import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";


const Blog15En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/3-reasons-why-you-should-be-saving"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
May 12, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>3 Reasons Why You Should Be Saving</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    There are many reasons why we should save, but here are 3 important ones. Savings give you…
                    <br/>
                    <ul>
<li>Peace of mind </li>
<li>The ability to purchase items and experiences that you want (e.g. una casita) without going into debt </li>
<li>Help keep your finances organized </li>

</ul>
When you save, however, make sure that you’re keeping your dinero safe in a bank! In 2019, 12.2% of Latino households in the US were unbanked, compared to 2.5% of white ones (FDIC). Additionally, in México, only ⅓ of adult women have a bank account (Visa). 
<br/>
                    <br/>

It’s important that our comunidades store our dinero in a bank because bank accounts offer…
<br/>
<ul>
<li>Safety </li>
<li>Convenience</li>
<li>Can give you interest on your money</li>
<li>Help you access credit more easily</li>
</ul>
So here’s to saving more and keeping your dinero safe!
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog15En;