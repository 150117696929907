import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog19En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/a-secured-credit-card-can-help-our-comunidades-build-credit"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Mar 27, 2023
 &nbsp;&bull;&nbsp; 2 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>A Secured Credit Card Can Help Our Comunidades Build Credit</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Are you between the ages of 18-21 and are ready to begin building your credit? If so, this article is for YOU.

                    <br />
                            <br />
Since getting an unsecured credit card by the age of 21 is not easy for a variety of reasons, there are still three ways that you can begin building credit. You can do so by…
<br/>
<br/>
<ul>
<li>Getting a credit card with your parent or guardian co-signing/opening an account with you</li>
<li>Tagging onto a relative’s credit card. Please note, however, that the user’s credit history and activity also impact your score</li>
<li>Taking advantage of a secured credit card</li>
</ul>
<b>What is a Secured Credit Card?</b> 
<br />
A secured credit card–also known as a credit builder card–is a credit card that is backed by a cash deposit. In other words, the user–the person using the credit card–needs to make a cash deposit to the credit card issuer to open up an account. 

<br />
                            <br />
The amount you deposit will be your line of credit. For example, if you deposit $100, then you would only be able to use $100 in that card. A secured credit card is used as any other credit card to make payments, but you can only use the amount that you deposited. 

<br />
                            <br />
A secured credit card aims to help a user establish or improve their credit. If a person does not have any credit history or needs to improve their credit, it can be more challenging for them to qualify for an unsecured credit card. Since secured credit cards tend to have more lenient approval requirements, getting a secured credit card can be a stepping stone to qualifying for other credit cards. If a secured credit card user shows that they can use credit responsibly and have built or improved their credit score, they will be able to transition to an unsecured credit card. 
<br />
                            <br />

Lastly, if you close your secured credit card account in good standing–meaning that you have made all your payments on time and in full–you will get your deposit back. 
<br />
                            <br />


<b>3 Tips to Optimize a Secured Credit Card</b>
<br />
Here are three tips we recommend when using a secured credit card to establish a strong credit:
<ul>
<li>Pay your balance on time and in full </li>
<li>Don’t spend more on your credit card than you have in your bank account </li>
<li>When you have an established credit score, check it regularly </li>
</ul>

<b>Why a Nuestro Secured Credit Card?</b>
<br />
Nuestro designed a secured credit card with our comunidades’ needs in mind. The major drawbacks of secured credit cards are that they can have really high fees and/or a high APR, but Nuestro’s secured credit card doesn't. It has zero fees and a 0% APR.

<br />
                            <br />
We intimately understand our comunidades and are committed to providing them with the most accessible lending products that are also culturally attentive. 

<br />
                            <br />
This is our way of helping our people on our own terms. 
<br />
                            <br />

If you are ready to take advantage of our secured credit card to help you build credit, sign up for our<a rel="noopener noreferrer" href="https://nuestro.info"> waitlist </a>today! 
<br />
                            <br />

And if you have DACA, you can also sign up for our<a rel="noopener noreferrer" href="https://nuestro.info"> waitlist </a> for a product beta test!
<br />
                            <br />

We can’t wait to see you flourish financially porque…

<br/>
                    <br/>
¡Sí se puede! 


<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog19En;