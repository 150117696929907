import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog14Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/where-should-i-store-my-dinero"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
May 18, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
               
                    <h1>¿Dónde Guardo Mi Dinero?</h1>
                    <br/>
                    <p>
                    ¡Debe guardar su dinero en un banco asegurado por la FDIC!
                    <br/>
                    <br/>
                    FDIC significa Corporación Federal de Seguros de Depósitos, y es una agencia federal que protege el dinero de un cliente en un banco en caso de quiebra o robo bancario. El monto máximo asegurable es de $250,000 por depositante. Esto significa que si tiene $200,000 almacenados en un banco asegurado por la FDIC y hay una quiebra bancaria, aún recibirá sus $200,000. Por lo tanto, almacenar su dinero en un banco asegurado por la FDIC es completamente seguro y está asegurado.
<br/>
                    <br/>

                    Al abrir una cuenta bancaria asegurada por la FDIC, obtendrá acceso a una cuenta corriente y de ahorros. Una cuenta corriente es aquella que le permite hacer depósitos y retiros. Una cuenta de ahorro, por otro lado, es aquella en la que puede almacenar dinero que desea utilizar en el futuro.
<br/>
                    <br/>

                    Cuando guarda su dinero en un banco, no hay cargos por depositar su dinero en su cuenta y puede proteger su dinero de errores y fraudes. También puede tener el control total de su dinero y administrarlo más fácilmente. Por ejemplo, podría...
<ul>
<li>Rastrear su dinero más fácilmente</li>
<li>Pagar tus facturas en un solo lugar, desde donde esté</li>
<li>Enviar dinero a familiares y amigos</li>
<li>Obtengener dinero más rápido mediante depósito directo (por ejemplo, cheques de pago de su empleador)</li>
<li>Extracto bancario, que es un registro de la actividad en su cuenta</li>
</ul>
Como puede ver, hay muchas ventajas en la banca, ¡así que hoy es el día que mantendrá su dinero seguro en un banco!
<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>debito</b>
                    <b>deuda</b>
                    <b>comunidad</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog14Es;