import React, { useState } from 'react';
import PropTypes from 'prop-types';
import "../../styles/blog_style.css";
import "../../styles/style.css";
import RSSUsage from '../share_social/english';
import shareIcon from "../../assets/icons/share.png"; // Import the share icon image

const Blog_card = ({ date, bookmarkCount, heartCount, commentCount, author, title, text, buttonLink, backgroundImage, blogLink }) => {
  const [isShareOpen, setIsShareOpen] = useState(false); // State to track share icon click

  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`, // Set the background image dynamically
    backgroundPosition: 'center', // Center the background image
    backgroundSize: 'cover', // Cover the entire container with the background image
    backgroundRepeat: 'no-repeat', // Do not repeat the background image
    position: 'relative', // Make the container relative for absolute positioning of the pseudo-element
  };

  const overlayStyle = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(33, 67, 136, 0.6)', // Blue filter color with 50% opacity
  };

  const handleShareIconClick = () => {
    setIsShareOpen(true); // Open the share component
  };

  const handleCloseShare = () => {
    setIsShareOpen(false); // Close the share component
  };

  return (
    <div className="example-2 card">
      <div className="wrapper" style={cardStyle}>
        <div style={overlayStyle}></div>
        <div className="header">
          <div className="date">
            <span className="author">{author}</span>
            <br />
            <span className="month">{date.month}</span>
            <span className="day"> {date.day},</span>
            <span className="year"> {date.year} .</span>
            <span className="time"> {date.time}</span>
          </div>
          {/* Share icon */}
          <img src={shareIcon} alt="Share" onClick={handleShareIconClick} className="share-icon" />
        </div>
        <div className="data">
          <div className="content">
            <h2 className="title"><a href={buttonLink} class="title_link">{title}</a></h2>
          </div>
        </div>
      </div>
      {/* Render RSSUsage component if share icon is clicked */}
      {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink={blogLink} />}
    </div>
  );
};

Blog_card.propTypes = {
  date: PropTypes.shape({
    day: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  }).isRequired,
  bookmarkCount: PropTypes.number.isRequired,
  heartCount: PropTypes.number.isRequired,
  commentCount: PropTypes.number.isRequired,
  author: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  blogLink: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

export default Blog_card;
