import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/navbar/english";
import Footer from "../../components/footer/english";
import Blog_card from "../../components/blog_card/english";
import b1 from "../../assets/images/b1.webp";
import b2 from "../../assets/images/b2.webp";
import b3 from "../../assets/images/b3.webp";
import b4 from "../../assets/images/b4.jpg";
import b5 from "../../assets/images/b5.jpg";
import b6 from "../../assets/images/b6.jpg";
import b7 from "../../assets/images/b7.jpg";
import b8 from "../../assets/images/b8.jpg";
import b9 from "../../assets/images/b9.jpg";
import b10 from "../../assets/images/b10.jpg";
import b11 from "../../assets/images/b11.jpg";
import b12 from "../../assets/images/b12.jpg";
import b13 from "../../assets/images/b13.jpg";
import b14 from "../../assets/images/b14.jpg";
import b15 from "../../assets/images/b15.jpg";
import b16 from "../../assets/images/b16.jpg";
import b17 from "../../assets/images/b17.jpg";
import b18 from "../../assets/images/b18.jpg";
import b19 from "../../assets/images/b19.jpg";
import b20 from "../../assets/images/b20.jpg";
import b21 from "../../assets/images/b21.jpg";
import bg_video from "../../assets/videos/blog_background.mp4";
import "../../styles/blog_style.css";


const BlogEn = () => {
    const blog1 = {
        date: { day: '8', month: 'Dec', year: '2023', time:'4min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Jane Flautt',
        title: 'Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...',
        blogLink: '/nuestro-features-part-3-enrico-lopez-yañez-principal-pops-director-at-the-nashville-symphony',
        buttonLink: '/blog/nuestro-features-part-3-enrico-lopez-yañez-principal-pops-director-at-the-nashville-symphony',
        backgroundImage: b1,
      };
      const blog2 = {
        date: { day: '30', month: 'Dec', year: '2023', time:'3min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Jane Flautt',
        title: 'Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at...',
        blogLink: '/nuestro-features-part-2-elizabeth-narváez-vega-college-success-coach-at-conexión-américas',
        buttonLink: '/blog/nuestro-features-part-2-elizabeth-narváez-vega-college-success-coach-at-conexión-américas',
        backgroundImage: b2,
      };
      const blog3 = {
        date: { day: '31', month: 'Sep', year: '2023', time:'3min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Jane Flautt',
        title: 'Nuestro Features, Part 1: Luis Pedraza, Resource Coordinator at TIRRC',
        blogLink: '/nuestro-features-part-1-luis-pedraza-resource-coordinator-at-tirrc',
        buttonLink: '/blog/nuestro-features-part-1-luis-pedraza-resource-coordinator-at-tirrc',
        backgroundImage: b3,
      };
      const blog4 = {
        date: { day: '28', month: 'Aug', year: '2023', time:'3min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Jane Flautt',
        title: 'Meet Israel Tovar, Nuestro’s Director of Financial Literacy',
        blogLink: '/meet-israel-tovar-nuestro-s-director-of-financial-literacy',
        buttonLink: '/blog/meet-israel-tovar-nuestro-s-director-of-financial-literacy',
        backgroundImage: b4,
      };
      const blog5 = {
        date: { day: '24', month: 'Jul', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'Where can I start investing?',
        blogLink: '/where-can-i-start-investing',
        buttonLink: '/blog/where-can-i-start-investing',
        backgroundImage: b5,
      };
      const blog6 = {
        date: { day: '18', month: 'Jul', year: '2023', time:'2min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'Help! What is the stock market?',
        blogLink: '/help-what-is-the-stock-market',
        buttonLink: '/blog/help-what-is-the-stock-market',
        backgroundImage: b6,
      };
      const blog7 = {
        date: { day: '11', month: 'Jul', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'Secure Your Financial Futuro with Investing',
        blogLink: '/secure-your-financial-futuro-with-investing',
        buttonLink: '/blog/secure-your-financial-futuro-with-investing',
        backgroundImage: b7,
      };
      const blog8 = {
        date: { day: '6', month: 'Jul', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'Take Control of Your Debt',
        blogLink: '/take-control-of-your-debt',
        buttonLink: '/blog/take-control-of-your-debt',
        backgroundImage: b8,
      };
      const blog9 = {
        date: { day: '22', month: 'Jun', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'How Can I Use Debt to Build Assets?',
        blogLink: '/how-can-i-use-debt-to-build-assets',
        buttonLink: '/blog/how-can-i-use-debt-to-build-assets',
        backgroundImage: b9,
      };
      const blog10 = {
        date: { day: '15', month: 'June', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'What Are Common Types of Debt?',
        blogLink: '/what-are-common-types-of-debt',
        buttonLink: '/blog/what-are-common-types-of-debt',
        backgroundImage: b10,
      };
      const blog11 = {
        date: { day: '8', month: 'Jun', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'What is debt?',
        blogLink: '/what-is-debt',
        buttonLink: '/blog/what-is-debt',
        backgroundImage: b11,
      };
      const blog12 = {
        date: { day: '3', month: 'Jun', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'Let’s Talk About Credit and Debit Cards',
        blogLink: '/let-s-talk-about-credit-and-debit-cards',
        buttonLink: '/blog/let-s-talk-about-credit-and-debit-cards',
        backgroundImage: b12,
      };
      const blog13 = {
        date: { day: '24', month: 'May', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'Help! What are Banking Transactions?',
        blogLink: '/help-what-are-banking-transactions',
        buttonLink: '/blog/help-what-are-banking-transactions',
        backgroundImage: b13,
      };
      const blog14 = {
        date: { day: '18', month: 'May', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'Where Should I Store My Dinero?',
        blogLink: '/where-should-i-store-my-dinero',
        buttonLink: '/blog/where-should-i-store-my-dinero',
        backgroundImage: b14,
      };
      const blog15 = {
        date: { day: '12', month: 'May', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Jane Flautt',
        title: '3 Reasons Why You Should Be Saving',
        blogLink: '/3-reasons-why-you-should-be-saving',
        buttonLink: '/blog/3-reasons-why-you-should-be-saving',
        backgroundImage: b15,
      };
      const blog16 = {
        date: { day: '4', month: 'May', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'What is an Emergency Fund?',
        blogLink: '/what-is-an-emergency-fund',
        buttonLink: '/blog/what-is-an-emergency-fund',
        backgroundImage: b16,
      };
      const blog17 = {
        date: { day: '27', month: 'Apr', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Jane Flautt',
        title: 'El Poder del Budget',
        blogLink: '/el-poder-del-budget',
        buttonLink: '/blog/el-poder-del-budget',
        backgroundImage: b17,
      };
      const blog18 = {
        date: { day: '20', month: 'Apr', year: '2023', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: '3 Tips to Take Care of Nuestro Dinero',
        blogLink: '/3-tips-to-take-care-of-nuestro-dinero',
        buttonLink: '/blog/3-tips-to-take-care-of-nuestro-dinero',
        backgroundImage: b18,
      };
      const blog19 = {
        date: { day: '27', month: 'Mar', year: '2023', time:'2min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'A Secured Credit Card Can Help Our Comunidades Build Credit',
        blogLink: '/a-secured-credit-card-can-help-our-comunidades-build-credit',
        buttonLink: '/blog/a-secured-credit-card-can-help-our-comunidades-build-credit',
        backgroundImage: b19,
      };
      const blog20 = {
        date: { day: '10', month: 'Mar', year: '2023', time:'2min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Panthelys Theodorakopoulos',
        title: 'The Importance Of Building Credit',
        blogLink: '/the-importance-of-building-credit',
        buttonLink: '/blog/the-importance-of-building-credit',
        backgroundImage: b20,
      };
      const blog21 = {
        date: { day: '21', month: 'Oct', year: '2022', time:'1min' },
        bookmarkCount: 0,
        heartCount: 18,
        commentCount: 3,
        author: 'Diego Eguiarte',
        title: 'Bienvenidos a Nuestro. The Moment is Ours.',
        blogLink: '/bienvenidos-a-nuestro-the-moment-is-ours',
        buttonLink: '/blog/bienvenidos-a-nuestro-the-moment-is-ours',
        backgroundImage: b21,
      };
  return (
    <div className="blog_bg">
      <video loop="true" autoplay="true" muted id="bgVideo">
  <source src={bg_video} type="video/mp4"/>
</video>
<div className="content">
        <Navbar langToggleHref="/es/blog"/>
        <Container style={{padding:'3rem 0rem'}}>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog1} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog2} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog3} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog4} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog5} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog6} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog7} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog8} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog9} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog10} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog11} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog12} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog13} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog14} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog15} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog16} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog17} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog18} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog19} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog20} /></Col>
            </Row>
            <Row className="main_card">
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                <Blog_card {...blog21} /></Col>
                <Col md={6} sm={12} className="m-sm-2 m-lg-0 m-md-0">
                </Col>
            </Row>
        </Container>
  <Footer/>
  </div>
    </div>
  );
};

export default BlogEn;
