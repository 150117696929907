import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog6Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/help-what-is-the-stock-market"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jul 18, 2023
 &nbsp;&bull;&nbsp; 2 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Ayuda ¿Que es el mercado de valores?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    El mercado/bolsa de valores, como cualquier otro mercado, es un lugar donde las personas compran y venden bienes, en este caso, las personas venden partes de empresas. Las acciones son partes de empresas que son propiedad de las personas. En otras palabras, comprar una acción significa que será propietario de una pequeña porción de la empresa. Invertir en el mercado/la bolsa de valores es una de las mejores maneras de crear riqueza y asegurar su futuro financiero.
                    <br/>
                    <br/>

Antes de invertir en la bolsa de valores, es importante aprender a realizar un seguimiento de su rendimiento. Hay tres índices principales en los EE. UU. que pueden ayudarle con eso:
<br/>
                    <br/>

El Índice Dow Jones rastrea las 30 empresas más grandes y establecidas en los Estados Unidos, como Coca Cola, Walmart y Disney.
<br/>
                    <br/>

El índice S & P 500 rastrea las 500 empresas más grandes de EE. UU.
<br/>
                    <br/>

El índice Nasdaq rastrea las empresas de tecnología y relacionadas con Internet más grandes del mundo, como Apple y Microsoft.
<br/>
                    <br/>

Cuándo se invierte en la bolsa de valores, se debe comprender la diferencia entre acciones individuales y fondos registrados.
<br/>
                    <br/>

Una acción individual es propiedad de una empresa, y si esa empresa crece, sus acciones crecen. Si la empresa quiebra, sus acciones podrían perder su valor. Mientras que un fondo indexado es un paquete de acciones de diferentes compañías. Dado que es un paquete, incluso si una de las empresas quiebra, aún tendría acciones y ganancias de las otras empresas. Le recomendamos que evalúe cada uno y decida cuál se adapta mejor a su situación financiera.
<br/>
                    <br/>

Finalmente, podría preguntarse, ¿por qué debo invertir en la bolsa de valores? En primer lugar, la bolsa de valores históricamente ha arrojado un promedio del 10%. Además, invertir no es apostar porque estás invirtiendo en empresas reales cuyo objetivo es ganar dinero. E invertir consistentemente a largo plazo es lo que lo ayudará a generar riqueza.
<br/>
                    <br/>

¡Vamos a generar riqueza juntos, mi gente!
<br/>
                    <br/>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog6Es;