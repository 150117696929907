import React from 'react'
import "../../styles/style.css"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import tiktok from "../../assets/icons/tiktok.webp";
import instagram from "../../assets/icons/instagram.webp";
import facebook from "../../assets/icons/facebook.webp";
import youtube from "../../assets/icons/youtube.webp";
import twitter from "../../assets/icons/twitter.webp";
import call from "../../assets/icons/telephone.png";

const FooterEn = () => {
  return (
    <>
    <footer className="main-footer">

<div className="container">
    <div className="widgets-section">
        <div className="row clearfix align-items-center">

            <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget services-widget">

                    <div className="footer-service-list" style={{listStyle:'none', lineHeight:'18px'}}>
                        <a target="_blank"  rel="noopener noreferrer"
                                href="https://app.termly.io/document/privacy-policy/bd9b6b40-9f99-45eb-a7ac-9656f6fc20ed" style={{fontSize:'10px'}}>Privacy Policy</a><br/>
                                <a target="_blank"  rel="noopener noreferrer"
                                href="https://app.termly.io/document/terms-of-use-for-saas/ae898e7a-4d4f-49dc-b1bf-666b4437a680" style={{fontSize:'10px'}}>Terms and Conditions</a><br/>
                                <a target="_blank"  rel="noopener noreferrer"
                                href="https://app.termly.io/document/eula/0f64760c-8bc5-49e0-91b0-f4ae41099d35" style={{fontSize:'10px'}}>EULA</a><br/>
                                <a target="_blank"  rel="noopener noreferrer"
                                href="https://app.termly.io/document/disclaimer/1807630b-4a48-4ce8-921e-cdc823014549" style={{fontSize:'10px'}}>Disclaimer</a><br/>
                                <a target="_blank"  rel="noopener noreferrer"
                                href="https://app.termly.io/document/acceptable-use-policy/b99a5bff-ce7a-4d43-bffe-c79109dc2553" style={{fontSize:'10px'}}>Acceptable Use Policy</a><br/>
                                <a target="_blank"  rel="noopener noreferrer"
                                href="/en/disclosures" style={{fontSize:'10px', fontWeight:'bold'}}>DISCLOSURES</a><br/>
                    </div>

                </div>
            </div>

            <div className="text-center col-lg-4 col-md-12 col-sm-12 pt-3">
                <div className="social-links">
                    <a href="https://www.tiktok.com/@nuestrowallet" target="_blank" rel="noopener noreferrer"><img alt="tiktok_logo" src={tiktok} /></a>
                    
                    <a href="https://www.instagram.com/nuestrowallet" target="_blank" rel="noopener noreferrer"><img alt="instagram_logo" src={instagram}/></a>
                    
                    <a href="https://www.facebook.com/nuestrowallet/" target="_blank" rel="noopener noreferrer"><img alt="facebook_logo" src={facebook}/></a>
                    
                    <a href="https://twitter.com/NuestroWallet" target="_blank" rel="noopener noreferrer"><img alt="twitter_logo" src={twitter}/></a>
                    
                    <a href="https://www.youtube.com/channel/UCLoqN-UhpkP1qZEyIDCdUbw" target="_blank" rel="noopener noreferrer"><img alt="youtube_logo" src={youtube}/></a>
                    
                </div>
                <a href="https://maps.app.goo.gl/pWnYTPRGGAXVzJuC6" target="_blank" rel="noopener noreferrer" style={{color:'#ffffff', fontSize:'13px'}}>444 Metroplex Dr Unit B-225, Nashville, TN 37211</a><br/>
                <a href="https://maps.app.goo.gl/uY3T7Tac48H25r5u8" target="_blank" rel="noopener noreferrer" style={{color:'#ffffff', fontSize:'13px'}}> 405 Thompson St, Shelbyville, TN 37160</a><br/>
                <a href="tel:(615) 800-6181" style={{color:'#ffffff', fontSize:'12px', textDecoration:'none'}}><img alt="call icon" src={call} width="15px"/> (615) 800-6181</a>
            </div>

            <div className="footer-bottom col-lg-4 col-md-12 col-sm-12">
                <div className="clearfix">
                    <div className="text-center">
                        <div className="copyright mb-3 ps-lg-5 ms-lg-5">© 2023 Nuestro Financial LLC</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

</footer>
    </>
  )
}

export default FooterEn;