import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog20En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/the-importance-of-building-credit"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
JMar 10, 2023
 &nbsp;&bull;&nbsp; 2 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>The Importance Of Building Credit</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    My name is Israel Tovar (he/él), and I am excited to have joined Nuestro as its Director of Financial Literacy. I will work hard to ensure that Nuestro’s users are financially successful.
                    <br/>
                    <br/>

I was born in Tijuana, México but was raised in Los Ángeles, California and Nashville, Tennessee. I received a BA in Ethnic Studies with honors and distinction from Yale University and a Master in Education from Stanford University. 
<br/>
                    <br/>

I served as a middle and high school social studies teacher for 5 years in San José, Nashville, and Washington D.C. before becoming a financial educator. For the past couple years, I’ve had the immense privilege of providing anti-racist, culturally responsive financial literacy to teachers of color across the country. 
<br/>
                    <br/>

Now with Nuestro, I am beyond excited to help our Latino comunidades flourish financially.
<br/>
                    <br/>

<b>What is Nuestro?</b>
<br/>
Nuestro provides our comunidades with access to entry-level banking products and financial literacy education to close the financial literacy gap that impacts the Latino community. 
<br/>
                    <br/>

We strive to help our comunidades deepen their financial literacy, improve their financial habits, and build generational wealth. 
<br/>
                    <br/>

Nuestro was created to help our comunidades take control of our financial future. Nuestro is our way of helping our people on our own terms. 
<br/>
                    <br/>

One of the ways we want our comunidades to take control of their financial future is by helping them build and improve their credit. 
<br/>
                    <br/>

<b>Why is it Important to Build Credit?</b>
<br/>
When you build a good credit score, you are able to more easily gain access to resources that you need to live and save money on major life purchases. For example, when you have a good credit score, you can be given better interest rates when you take out a house mortgage or get a car loan. Additionally, by having a good credit score, you can be approved more easily for important things like an apartment lease.  

<br/>
                    <br/>
There are five credit score ranges: 
<br/>
                    <br/>

<b>Excellent:</b> 800–850<br/>
<b>Very Good:</b> 740–799<br/>
<b>Good:</b> 670–739<br/>
<b>Fair:</b> 580–669<br/>
<b>Poor:</b> 300–579

<br/>
                    <br/>
You do not need to have an excellent credit score. You just need to avoid having fair or poor scores because that is the range lenders (e.g. landlords, banks) can reject you or give you a higher interest rate. Of course, the higher the credit score, the better because a higher score signals to lenders that you are more likely to make your monthly payments on time and in full. The average score of Latinos in 2019 was 701, which falls in the “Good” score range (<a href="https://www.investopedia.com/average-credit-scores-by-race-5214521" rel="noopener noreferrer">Investopedia</a>). 
<br/>
                    <br/>

There are five factors that impact your credit score:
<ol>
<li><b>Payment History (35%):</b> Shows whether a borrower has paid their obligations on time. </li>
<li><b>Total Amount Owed (30%):</b> The percentage of credit that a borrower is using. </li>
<li><b>Length of Credit History (15%):</b> How long a borrower has been using credit. </li>
<li><b>Types of Credit (10%):</b> The variety of different credits (e.g. car loan, credit card, mortgage) a borrower has.</li>
<li><b>New Credit (10%):</b> The number of new credit accounts a borrower opens. </li>
</ol>
It is important to highlight that it does take time to build or improve your credit. However, the sooner you take control of your credit, the sooner you will see results.
<br/>
                    <br/>

Nuestro is committed to helping our comunidades improve their credit because our comunidades deserve to thrive financially. We are rooting for you! 


<br/>
                    <br/>

¡Sí se puede! 
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Finance</b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog20En;