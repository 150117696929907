import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog7Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/secure-your-financial-futuro-with-investing"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jul 11, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Asegure su futuro financiero con inversiones</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Una de las formas en que nuestras comunidades pueden prosperar financieramente es mediante la inversión, el acto de comprar activos que pueden generar ganancias en el futuro.
                    <br/>
                    <br/>
                    Quizás se pregunte: ¿por qué debería invertir para el futuro cuando actualmente necesitamos dinero? 
                    <br/>
                    <br/>
                    Si usted quiere:

<ul>
<li>Jubilarse</li>
<li>Crear riqueza y/o</li>
<li>Transmitir la riqueza a los seres queridos.</li>
</ul>

<center><p style={{fontStyle:'italic'}}>¡Necesita aprender y comenzar a invertir!</p></center>

También debe aprender y comenzar a invertir debido a la inflación, que es el aumento de los precios y la disminución de su poder adquisitivo con el tiempo. Si no invierte, su dinero perderá valor debido a la inflación. Mientras tanto, cuando invierte, puede aprovechar algo conocido como interés compuesto.
<br/>
                    <br/>

                    El interés compuesto es esencialmente interés ganado sobre interés, lo que significa que cuanto más invierte y cuanto antes lo haga, más interés ganará su dinero. Lo mejor de esto es que una vez que usted invierte, su dinero crecerá sin que tenga que hacer nada.
<br/>
                    <br/>

                    En las próximas publicaciones del blog, aprenderá más sobre el mercado de valores, de qué  manera funciona para hacer crecer su dinero y cómo comenzar a invertir.
<br/>
                    <br/>

                    Aquí está la creación de riqueza a través de la inversión porque ¡Sí se puede, mi gente!
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog7Es;