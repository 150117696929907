import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";

const Blog12Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/let-s-talk-about-credit-and-debit-cards"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jun 23, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Hablemos de Tarjetas de Crédito y Débito</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Como sabes, existen diferentes tipos de tarjetas bancarias. En esta publicación, le proporcionaremos las diferencias claves entre las dos principales tarjetas bancarias: tarjetas de crédito y débito.
                    <br/>
                    <br/>
                    <p style={{textDecoration:'underline'}}>Tarjeta de crédito:</p>

                    Este es dinero que pide prestado al emisor de la tarjeta para hacer compras o retirar efectivo. El dinero que paga está sujeto a una tasa de interés. Las tarjetas de crédito tienen un límite de crédito, que es la cantidad máxima de dinero que puede pedir prestada. También pueden cobrar tarifas y puede obtener recompensas por viajar o reembolsos en efectivo, según el tipo de tarjeta que emita.
<br/>
                    <br/>
<p style={{textDecoration:'underline'}}>Tarjeta de débito:</p>

Esta tarjeta le permite gastar dinero utilizando los fondos disponibles en su cuenta bancaria. Tiene pocos o ningún cargo a menos que gaste más de lo que tiene disponible en su cuenta, lo que se conoce como cargo por sobregiro. A diferencia de las tarjetas de crédito, con las tarjetas de débito, no puede obtener recompensas ni generar crédito.
<br/>
                    <br/>
<p style={{textDecoration:'underline'}}>Tarjetas físicas vs virtuales:</p>

Tanto las tarjetas de débito como las de crédito están disponibles física y virtualmente. Éstas tarjetas constan con un número de tarjeta de 16 dígitos, un código de valor de verificación de tarjeta (CVV) único y una fecha de vencimiento. Las tarjetas virtuales solo existen en formato digital. Puede usar una tarjeta virtual, tal como lo haría con una tarjeta física.
<br/>
                    <br/>

                    Ahora que conoce la diferencia entre éstas dos tarjetas, puede usarlas de manera más efectiva para tomar el control de sus finanzas.

<br/>
                    <br/>
                    ¡Sí se puede!
<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>debito</b>
                    <b>deuda</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog12Es;