import React from "react";
import "../../styles/style.css";
import "../../styles/slidein.css";
import NavbarEn from "../../components/navbar/spanish";
import happyboy from "../../assets/images/FL_user.png";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/spanish";
import FL1 from "../../assets/images/FLes1-8.webp";
import FL2 from "../../assets/images/FLes2-8.webp";
import FL3 from "../../assets/images/FLes3-8.webp";
import m1 from "../../assets/images/masonary/m1.jpg";
import m2 from "../../assets/images/masonary/m2.jpg";
import m3 from "../../assets/images/masonary/m3.jpg";
import m4 from "../../assets/images/masonary/m4.jpg";
import m5 from "../../assets/images/masonary/m5.jpg";
import m6 from "../../assets/images/masonary/m6.jpg";
import m7 from "../../assets/images/masonary/m7.jpg";
import m8 from "../../assets/images/masonary/m8.jpg";
import m9 from "../../assets/images/masonary/m9.jpg";
import m10 from "../../assets/images/masonary/m10.jpg";
import m12 from "../../assets/images/masonary/m12.jpg";
import m16 from "../../assets/images/masonary/m16.jpg";

const FLEs = () => {
  return (
    <div className="home_main">
      <NavbarEn langTogglehref="/en/financial-literacy" />
      <div>
        <Container>
          <Row>
            <Col sm={3} md={3} xs={6} style={{ zIndex: 50 }}>
              <div style={{ textAlign: "right" }}>
                <img
                  alt="happy nuestro user"
                  src={happyboy}
                  className="slide-in-left fl-banner-img"
                  style={{ zIndex: 50 }}
                />
              </div>
            </Col>
            <Col sm={9} md={9} xs={6}>
              <div className="slide-in-right fl_heading">
                <h1>
                  Toma control de
                  <br />
                  tu
                  <span
                    style={{
                      color: "rgb(232, 0, 120)",
                      fontFamily: "RockoUltraFlF",
                    }}
                  >
                    {" "}
                    futuro financiero
                  </span>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container
        fluid
        className="pb-lg-5 pt-lg-5 pt-4"
        style={{ backgroundColor: "#ffffff" }}
      >
        <Row className="d-flex">
          <Col sm={5}></Col>
          <Col sm={7}>
            {/* Content for the second column */}
            <h4 className="fl_description">
              Somos parte de la comunidad y estamos comprometidos en proveer
              <span
                style={{ color: "rgb(232, 0, 120)", fontFamily: "AvenirBold" }}
              >
                {" "}
                educación financiera gratuita
              </span>{" "}
              que sea fácil de entender y culturalmente sensible.
            </h4>
            <h1 className="free_workshop slide-in-left">TALLERES GRATUITOS</h1>
            <div className="text-lg-end pb-3 pt-2 text-center">
              <a
                className="signup_button"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfHhN7ju5nuvMAqRD2Q_L1soFVzgAO6lYLOCwVuHO66iwRPlg/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                INSCRIBIRSE
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="fl_safe_space">
        <Container className="pt-5 pb-1 mt-4">
          <Row>
            <Col md={4} sm={12} className="p-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.nuestrowallet.com"
              >
                <img
                  alt="dinero with nuestro"
                  src={FL1}
                  width="230px"
                  className="circular-grow"
                />
              </a>
            </Col>
            <Col md={4} sm={12} className="p-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.nuestro.loans"
              >
                <img
                  alt="community fund"
                  src={FL2}
                  width="230px"
                  className="circular-grow"
                />
              </a>
            </Col>
            <Col md={4} sm={12} className="p-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/en/financial-literacy"
              >
                <img
                  alt="financial literacy resources"
                  src={FL3}
                  width="230px"
                  className="circular-grow"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="fl_dinner">
                Nuestro<span> es un</span> espacio de confianza
                <span>
                  {" "}
                  para que nuestras
                  <br />
                  comunidades puedan hablar y aprender{" "}
                </span>
                sobre dinero.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <div className="fl_dinero m-3">
                <p>
                  "La explicación con ejemplos fáciles y el hacer que los
                  usuarios participen y se ven diferentes puntos de vista." ​
                  <br />
                  <br />
                  -Lizeth
                </p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="fl_dinero m-3">
                <p>
                  "I like the simplification of a complicated topic while also
                  keeping the material relevant!"
                </p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="fl_dinero m-3">
                <p>
                  "I really liked the way Israel explained difficult topics in a
                  simple and understandable way. I also liked how the staff was
                  open to questions and answered them in a knowledgeable way."
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="fl_dinner">
                <span> Testimonials from community </span> organizations
                <span> and </span>partners{" "}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <div className="fl_testimonials m-2">
                <p>
                  “I love how accessible your material is for young people, and
                  the reality that it is needed was definitely clear in the
                  questions and lack of overall understanding of credit today’s
                  participants demonstrated. It has been a delight to host
                  Nuestro and you as its representatives.”
                  <br />
                  <br />
                  <span>
                    -Ellen Zinkiewicz, Director of the Office of Sponsored
                    Programs at Belmont University
                  </span>
                </p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="fl_testimonials m-2">
                <p>
                  “Tu enfoque dinámico y claro hizo que temas financieros
                  complejos fueran fáciles de entender para todos los
                  asistentes. Realmente disfruté colaborar contigo en este
                  evento tan valioso y necesario para nuestras familias.”
                  <br />
                  <br />
                  <span>
                    -Maura Escobar, Workforce Development Manager at Conexión
                    Américas
                  </span>
                </p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="fl_testimonials m-2">
                <p>
                  “Me encantó la forma con la que explicas estos temas y la
                  forma en que mantienes enganchada a nuestra audiencia,
                  definitivamente una gran colaboración, la gente estuvo muy
                  contenta!”
                  <br />
                  <br />
                  <span>
                    -Marlene Cervantes Guerrero, Financial and Education
                    Coordinator at El Centro Hispano de Knoxville
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="pt-5 pb-3">
          <Row>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m1} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m2} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m3} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m4} width="100%" />
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m5} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m6} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m7} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m8} width="100%" />
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m9} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m10} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              {/* This column spans two rows */}
              <img alt="Nuestro organized workshops" src={m16} width="100%" />
            </Col>
            <Col sm={3} style={{ padding: 5 }}>
              <img alt="Nuestro organized workshops" src={m12} width="100%" />
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid className="py-4 fl_community">
        <h2>
          <span>MÁS EDUCACIÓN</span> COMUNITARIA VENDRÁ PRONTO!
        </h2>
        <h3>
          <span>Educación de</span> ITIN
        </h3>

        <h3>
          <span>Educación de</span> propiedad de vivienda{" "}
        </h3>

        <h3>
          <span>Educación</span> tecnológica
        </h3>
      </Container>

      <FooterEn />
    </div>
  );
};

export default FLEs;
