import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog11Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/what-is-debt"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jun 8, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>¿Que es Deuda?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Si estás planificando…

<ul>
<li>Continuar una educación superior</li> 
<li>Comprar un auto</li>
<li>Comprar una casa</li>
<li>Iniciar un negocio</li>

</ul>
¡Primero debes comprender que es la deuda! La deuda es dinero prestado que debe devolverse, generalmente, con intereses. Puede tener una fecha de vencimiento de pago fija o puede ser revolvente (línea crediticia en la cual se puede volver a disponer del dinero). Hay diferentes tipos de deuda.
<br/>
                    <br/>
Hay deuda asegurada, o deuda que es colateral. Colateral significa que los prestatarios tienen que prometer algo como garantía (por ejemplo, dinero) para el pago de un préstamo, y si el préstamo no se paga, el prestamista tomará la garantía. Los ejemplos de deuda garantizada incluyen préstamos para automóviles, hipotecas y tarjetas de crédito garantizadas.
<br/>
                    <br/>
Por otro lado, la deuda no garantizada no requiere ninguna garantía. Para este tipo de deuda, el prestamista decide otorgar el préstamo o crédito en función del puntaje crediticio del prestatario, y generalmente tiene tasas de interés más altas que los préstamos garantizados. Los ejemplos incluyen tarjetas de crédito y préstamos personales.
<br/>
                    <br/>

                    Las formas comunes de deuda incluyen:
<ul>
<li>Hipotecas</li>
<li>Presto de Auto</li>
<li>Deuda Estudiantil</li>
<li>Préstamo Personal</li>
<li>Tarjeta de Crédito</li>
</ul>
En las próximas publicaciones del blog, aprenderá más sobre cada tipo de deuda, ¡así que esté atento!
<br/>
<br/>
________________________________________________
<br/>
1 Revolvente significa que no está obligado a pagar su saldo (lo que debe) antes de una fecha exacta.
<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>debito</b>
                    <b>deuda </b>
                    <b>dinero </b>
                    <b>comunidad </b>
                    <b>Credito </b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog11Es;