import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog6En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/help-what-is-the-stock-market"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jul 18, 2023
 &nbsp;&bull;&nbsp; 2 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Help! What is the stock market?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    The stock market, like any other market, is a place where people buy and sell goods, in this case, people sell parts of companies. Stocks are parts of companies that are owned by people. In other words, buying a share means that you will own a small portion of the company. Investing in the stock market is one of the best ways to build wealth and secure your financial future.

                    <br/>
                    <br/>
Before investing in the stock market, it is important to learn how to track its performance. There are three main indexes in the US that can help you with that:

<br/>
                    <br/>
The Dow Jones Index tracks the 30 largest and most established companies in the United States, such as Coca Cola, Walmart, and Disney.

<br/>
                    <br/>
The <b>S&P 500</b> Index tracks the 500 largest companies in the US.

<br/>
                    <br/>
The <b>Nasdaq Index</b> tracks the world's largest Internet-related and technology companies, such as Apple and Microsoft.

<br/>
                    <br/>
When investing in the stock market, you must understand the difference between individual stocks and registered funds.

<br/>
                    <br/>
An individual stock is owned by a company, and if that company grows, its shares grow. If the company goes bankrupt, its shares could lose their value. While an index fund is a bundle of shares from different companies. Since it is a package, even if one of the companies goes bankrupt, you would still have shares and profits from the other companies. We recommend that you evaluate each one and decide which one best suits your financial situation.

<br/>
                    <br/>
Finally, you might ask yourself, why should I invest in the stock market? First, the stock market has historically returned an average of 10%. Also, investing is not gambling because you are investing in real companies whose goal is to make money and investing consistently for the long term is what will help you build wealth.

<br/>
                    <br/>
Here’s to building wealth, juntos!
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog6En;