import React, {useState, useContext} from "react";
import { Routes, Route } from 'react-router-dom';
import HomeEn from "./pages/home/english";
import HomeEs from "./pages/home/spanish";
import ContactEn from "./pages/contactus/english";
import ContactEs from "./pages/contactus/spanish";
import ProductEn from "./pages/products/english";
import ProductEs from "./pages/products/spanish";
import FLEn from "./pages/financial-literacy/english";
import FLEs from "./pages/financial-literacy/spanish";
import LegalEn from "./pages/disclosures/english";
import LegalEs from "./pages/disclosures/spanish";
import CFEn from "./pages/community_fund/english";
import CFEs from "./pages/community_fund/spanish";
import AboutEn from "./pages/about/english";
import AboutEs from "./pages/about/spanish";
import TeamEn from "./pages/team/english";
import TeamEs from "./pages/team/spanish";
import BlogEn from "./pages/blog/english";
import BlogEs from "./pages/blog/spanish";
import Blog1En from "./components/blogs/blog1/english";
import Blog2En from "./components/blogs/blog2/english";
import Blog3En from "./components/blogs/blog3/english";
import Blog4En from "./components/blogs/blog4/english";
import Blog5En from "./components/blogs/blog5/english";
import Blog6En from "./components/blogs/blog6/english";
import Blog7En from "./components/blogs/blog7/english";
import Blog8En from "./components/blogs/blog8/english";
import Blog9En from "./components/blogs/blog9/english"; 
import Blog10En from "./components/blogs/blog10/english";
import Blog11En from "./components/blogs/blog11/english";
import Blog12En from "./components/blogs/blog12/english";
import Blog13En from "./components/blogs/blog13/english";
import Blog14En from "./components/blogs/blog14/english";
import Blog15En from "./components/blogs/blog15/english";
import Blog16En from "./components/blogs/blog16/english";
import Blog17En from "./components/blogs/blog17/english";
import Blog18En from "./components/blogs/blog18/english";
import Blog19En from "./components/blogs/blog19/english";
import Blog20En from "./components/blogs/blog20/english";
import Blog21En from "./components/blogs/blog21/english";
import Blog1Es from "./components/blogs/blog1/spanish";
import Blog2Es from "./components/blogs/blog2/spanish";
import Blog3Es from "./components/blogs/blog3/spanish";
import Blog4Es from "./components/blogs/blog4/spanish";
import Blog5Es from "./components/blogs/blog5/spanish";
import Blog6Es from "./components/blogs/blog6/spanish";
import Blog7Es from "./components/blogs/blog7/spanish";
import Blog8Es from "./components/blogs/blog8/spanish";
import Blog9Es from "./components/blogs/blog9/spanish"; 
import Blog10Es  from "./components/blogs/blog10/spanish";
import Blog11Es  from "./components/blogs/blog11/spanish";
import Blog12Es  from "./components/blogs/blog12/spanish";
import Blog13Es  from "./components/blogs/blog13/spanish";
import Blog14Es  from "./components/blogs/blog14/spanish";
import Blog15Es  from "./components/blogs/blog15/spanish";
import Blog16Es  from "./components/blogs/blog16/spanish";
import Blog17Es  from "./components/blogs/blog17/spanish";
import Blog18Es  from "./components/blogs/blog18/spanish";
import Blog19Es  from "./components/blogs/blog19/spanish";
import Blog20Es  from "./components/blogs/blog20/spanish";
import Blog21Es  from "./components/blogs/blog21/spanish";
import "./styles/style.css"

// class App extends React.Component {
const App = () => {
  
  // render = () => {
    return (
        <Routes>
          <Route path="/" element={<HomeEn />} />
          <Route path="/es" element={<HomeEs />} />
          <Route path="/en/products" element={<ProductEn />} />
          <Route path="/es/products" element={<ProductEs />} />
          <Route path="/en/about" element={<AboutEn />} />
          <Route path="/es/about" element={<AboutEs />} />
          <Route path="/en/team" element={<TeamEn />} />
          <Route path="/es/team" element={<TeamEs />} />
          <Route path="/en/contact" element={<ContactEn />} />
          <Route path="/es/contact" element={<ContactEs />} />
          <Route path="/en/financial-literacy" element={<FLEn/>}/>
          <Route path="/es/financial-literacy" element={<FLEs/>}/>
          <Route path="/en/disclosures" element={<LegalEn/>}/>
          <Route path="/es/disclosures" element={<LegalEs/>}/>
          <Route path="/en/community-fund" element={<CFEn/>}/>
          <Route path="/es/community-fund" element={<CFEs/>}/>
          <Route path="/en/blog" element={<BlogEn/>}/>
          <Route path="/es/blog" element={<BlogEs/>}/>
          <Route path="/blog/nuestro-features-part-3-enrico-lopez-yañez-principal-pops-director-at-the-nashville-symphony" element={<Blog1En/>}/>
          <Route path="/blog/nuestro-features-part-2-elizabeth-narváez-vega-college-success-coach-at-conexión-américas" element={<Blog2En/>}/>
          <Route path="/blog/nuestro-features-part-1-luis-pedraza-resource-coordinator-at-tirrc" element={<Blog3En/>}/>
          <Route path="/blog/meet-israel-tovar-nuestro-s-director-of-financial-literacy" element={<Blog4En/>}/>
          <Route path="/blog/where-can-i-start-investing" element={<Blog5En/>}/>
          <Route path="/blog/help-what-is-the-stock-market" element={<Blog6En/>}/>
          <Route path="/blog/secure-your-financial-futuro-with-investing" element={<Blog7En/>}/>
          <Route path="/blog/take-control-of-your-debt" element={<Blog8En/>}/>
          <Route path="/blog/how-can-i-use-debt-to-build-assets" element={<Blog9En/>}/>
          <Route path="/blog/what-are-common-types-of-debt" element={<Blog10En/>}/>
          <Route path="/blog/what-is-debt" element={<Blog11En/>}/>
          <Route path="/blog/let-s-talk-about-credit-and-debit-cards" element={<Blog12En/>}/>
          <Route path="/blog/help-what-are-banking-transactions" element={<Blog13En/>}/>
          <Route path="/blog/where-should-i-store-my-dinero" element={<Blog14En/>}/>
          <Route path="/blog/3-reasons-why-you-should-be-saving" element={<Blog15En/>}/>
          <Route path="/blog/what-is-an-emergency-fund" element={<Blog16En/>}/>
          <Route path="/blog/el-poder-del-budget" element={<Blog17En/>}/>
          <Route path="/blog/3-tips-to-take-care-of-nuestro-dinero" element={<Blog18En/>}/>
          <Route path="/blog/a-secured-credit-card-can-help-our-comunidades-build-credit" element={<Blog19En/>}/>
          <Route path="/blog/the-importance-of-building-credit" element={<Blog20En/>}/>
          <Route path="/blog/bienvenidos-a-nuestro-the-moment-is-ours" element={<Blog21En/>}/>
          <Route path="/es/blog/nuestro-features-part-3-enrico-lopez-yañez-principal-pops-director-at-the-nashville-symphony" element={<Blog1Es/>}/>
          <Route path="/es/blog/nuestro-features-part-2-elizabeth-narváez-vega-college-success-coach-at-conexión-américas" element={<Blog2Es/>}/>
          <Route path="/es/blog/nuestro-features-part-1-luis-pedraza-resource-coordinator-at-tirrc" element={<Blog3Es/>}/>
          <Route path="/es/blog/meet-israel-tovar-nuestro-s-director-of-financial-literacy" element={<Blog4Es/>}/>
          <Route path="/es/blog/where-can-i-start-investing" element={<Blog5Es/>}/>
          <Route path="/es/blog/help-what-is-the-stock-market" element={<Blog6Es/>}/>
          <Route path="/es/blog/secure-your-financial-futuro-with-investing" element={<Blog7Es/>}/>
          <Route path="/es/blog/take-control-of-your-debt" element={<Blog8Es/>}/>
          <Route path="/es/blog/how-can-i-use-debt-to-build-assets" element={<Blog9Es/>}/>
          <Route path="/es/blog/what-are-common-types-of-debt" element={<Blog10Es/>}/>
          <Route path="/es/blog/what-is-debt" element={<Blog11Es/>}/>
          <Route path="/es/blog/let-s-talk-about-credit-and-debit-cards" element={<Blog12Es/>}/>
          <Route path="/es/blog/help-what-are-banking-transactions" element={<Blog13Es/>}/>
          <Route path="/es/blog/where-should-i-store-my-dinero" element={<Blog14Es/>}/>
          <Route path="/es/blog/3-reasons-why-you-should-be-saving" element={<Blog15Es/>}/>
          <Route path="/es/blog/what-is-an-emergency-fund" element={<Blog16Es/>}/>
          <Route path="/es/blog/el-poder-del-budget" element={<Blog17Es/>}/>
          <Route path="/es/blog/3-tips-to-take-care-of-nuestro-dinero" element={<Blog18Es/>}/>
          <Route path="/es/blog/a-secured-credit-card-can-help-our-comunidades-build-credit" element={<Blog19Es/>}/>
          <Route path="/es/blog/the-importance-of-building-credit" element={<Blog20Es/>}/>
          <Route path="/es/blog/bienvenidos-a-nuestro-the-moment-is-ours" element={<Blog21Es/>}/>
        </Routes>
    );
  }
// }

export default App;
