import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";


const Blog16Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/what-is-an-emergency-fund"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
May 4, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>¿Qué es un Fondo de Emergencia?</h1>
                    <br/>
                    <p>
                    ¿Sabe qué es un fondo de emergencia? Si no es así, asegúrese de leer toda esta publicación de blog.
                    <br/>
                    <br/>
                    Un fondo de emergencia es dinero que se ha reservado para gastos inesperados. Es súper importante que tenga un fondo de emergencia porque…
<ol>
<li>No se trata de si va a pasar una emergencia, sino de cuándo va a pasar</li>
<li>Tener uno evitará que se endeude</li>
<li>Le dará tranquilidad financiera y eso no tiene precio</li>
</ol>

Así que quizás se esté preguntando, "¿qué cuenta como una emergencia?" ¡Esa es una buena pregunta! Una emergencia es un evento inesperado que requiere atención y recursos inmediatos como…
<ol>
<li>Una pérdida de trabajo</li>
<li>Emergencias médicas o dentales</li>
<li>Problemas de auto</li>
</ol>
Sin embargo, antes de sacar dinero de su fondo de emergencia, deténgase y hágase estas preguntas:
<ol>
<li>¿Es inesperado?</li>
<li>¿Es urgente?</li>
<li>¿Es necesario?</li>
</ol>
Si respondió "sí" a todas estas preguntas, continúe y use su fondo de emergencia.
<br/>
¡Hoy es el día que usted tome control de su futuro financiero y construya un fondo de emergencia!


<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>debito</b>
                    <b>deuda</b>
                    <b>comunidad</b>
                    <b>Credito</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog16Es;