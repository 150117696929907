import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/dp.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog4En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/meet-israel-tovar-nuestro-s-director-of-financial-literacy"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Jane Flautt &nbsp;&bull;&nbsp; 
Aug 28, 2023
 &nbsp;&bull;&nbsp; 3 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                    <h1>Meet Israel Tovar, Nuestro’s Director of Financial Literacy</h1>
                    <br/>
                    <i>Israel Tovar is ready to help his community take control of their finances and develop healthy and confident relationships with money. </i>
                    <br/>
                    <br/>
                    <p>
                    Nuestro is excited to introduce Israel Tovar as the Director of Financial Literacy and a vital member of our team. Israel comes from a background rooted in education, community development, and financial empowerment, and he has strong ties to the Nashville community having grown up and worked here.  
                    <br/>
                    <br/>
Before moving back to Nashville to work at Nuestro, Israel received his master’s degree in education from Stanford University and worked as a teacher in California, Tennessee, and Washington, D.C. Israel also has pursued entrepreneurial opportunities, and most recently he co-founded The Dream Teacher Project with his sister in 2021, an organization which coaches teachers across America on how to take control of their finances and build wealth. Israel’s background in education is one of the reasons he felt so drawn to Nuestro as a company, and as Director of Financial Literacy, he is dedicated to guaranteeing that the products and services Nuestro provides are as community-based as possible. And this goal is more than just a professional one — Israel draws from his own personal experience growing up and living in Latino communities in Nashville and beyond. 
<br/>
                    <br/>
Israel was born in Tijuana, Mexico, and his family moved to Los Angeles, California, when he was very young. Just a few years later in 2007, his family moved again, this time to Nashville, Tennessee. The change from southern California to the South was at times a jarring one — Israel recalled how at the time of his family’s move there were fewer Latino communities in Nashville, especially compared to where he grew up in Los Angeles and Tijuana. By the time he was in high school and thinking about his future, he was ready to leave the South, especially as he came to terms with his queer identity as well, Israel explained. The next step: Yale University.
<br/>
                    <br/>
After graduating from Yale as a first-generation college student, Israel remembered feeling unprepared for the financial world he was entering — he had no generational wealth to fall back on nor any substantial financial literacy, despite the prestige of being a Yale graduate. Israel explained how his family’s “scarcity mindset” influenced his own personal relationship with money for much of his young life. Eventually, he was able to reimagine this scarcity mindset and transform it into an “abundance mindset,” as he called it, and was able to look at money “as more of a tool rather than something to be accumulated.” Ultimately, Israel was able to hit financial milestones at an earlier age than he ever imagined — and on a teacher’s salary. 
<br/>
                    <br/>
However, Israel recognizes how unique his journey with finances is, especially regarding his development of a financial mindset that differs so significantly from his parents’. Many second-generation Latino folks — who, like Israel, may have graduated as first-generation college students with very little financial literacy — still have the same relationship to money and wealth as their parents’ generation, despite making more money as they progress in their careers. It’s within this gap in education and financial confidence that Nuestro can step in, Israel explained. 
<br/>
                    <br/>
As a former teacher, Israel understands the power of education. Yet, he explained that education is an important piece of the puzzle, but it doesn’t automatically change someone’s scarcity mindset, fear of investing, or avoidance of budgeting, especially when that person may have complicated or distressing past experiences with money and financial security. Israel also recognizes that it’s harder than ever to find a job and achieve financial security in the current economic environment due to inflation, layoffs, and a difficult job market, so he urges folks to be forgiving of themselves and acknowledge that individual choices and education can only change so much. But the first step is education, Israel clarified, and this education needs to be culturally-attentive and inclusive of the lived experiences of the people being educated. 
<br/>
                    <br/>
Looking to the future of Nuestro and its first year on the ground, Israel hopes that Nuestro will help folks in communities across the Southeast begin to take control of their finances and to develop healthy and confident relationships with their money. As he dives into his position as Director of Financial Literacy at Nuestro, Israel said, “I’m really excited to be a part of this company and to see it flourish because I know it’s going to help.”
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog4En;