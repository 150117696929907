import React from "react";
import "../../styles/style.css";
import "../../styles/slidein.css";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/navbar/english";
import Footer from "../../components/footer/english";
import right_banner from "../../assets/images/CF_happy_girl.png";
import right_banner_2 from "../../assets/images/FL_fund_1-8.webp";
import CF1 from "../../assets/images/CF1-8.webp";
import CF2 from "../../assets/images/CF2-8.webp";
import CF3 from "../../assets/images/CF3-8.webp";
import CF4 from "../../assets/images/CF4-8.webp";
import CF5 from "../../assets/images/CF5-8.webp";
import CF6 from "../../assets/images/CF6-8.webp";
import Apply_now from "../../assets/documents/Nuestro - Community Fund Application.pdf";

const CFEn = () => {
  return (
    <div className="home_main">
      <Navbar langToggleHref="/es/community-fund" />
      <Container style={{ paddingTop: 0 }}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="CF_right_banner order-md-2 order-lg-2"
          >
            <img
              alt="Nuestro community fund happy user"
              src={right_banner}
              className="left_banner_cf slide-in-left"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="left_CF_box order-md-1 order-lg-1"
          >
            {" "}
            {/* Original order for medium and larger screens */}
            <div className="left_text_box slide-in-right">
              <p>
                The Nuestro Community Fund was established to close the
                financial literacy gap that impacts the Latino communities in
                the American Southeast by providing digestible, culturally
                responsive financial literacy to Latino youth in the region.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ paddingTop: 0 }}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="CF_right_banner_box grow-effect order-md-2 order-lg-2"
          >
            <img
              alt="financial literacy for latinos"
              src={right_banner_2}
              className="CF_left_banner"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="left_CF_box_2 order-md-1 order-lg-1"
          >
            <div className="left_text_box_2 grow-effect">
              <p>
                If you serve the Latino community in Tennessee, we invite you to
                apply to our Community Fund! We offer…
                <ul>
                  <li>Online financial literacy learning modules </li>

                  <li>$500-$2,000 in funding to implement the programming</li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col>
            <h4 className="CF_culture">
              Our <span>bicultural, bilingual</span> online
              <br /> learning modules include…
            </h4>
          </Col>
        </Row>
      </Container>
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col>
            <img
              alt="personal finance"
              src={CF1}
              width="150px"
              className="m-2"
            />
          </Col>
          <Col>
            <img alt="banking" src={CF2} width="150px" className="m-2" />
          </Col>
          <Col>
            <img
              alt="importance of credit"
              src={CF3}
              width="150px"
              className="m-2"
            />
          </Col>
          <Col>
            <img alt="DBT" src={CF4} width="150px" className="m-2" />
          </Col>
          <Col>
            <img alt="investing" src={CF5} width="150px" className="m-2" />
          </Col>
        </Row>
      </Container>
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col>
            <div className="eligible_text">
              <img alt="eligible for funds?" src={CF6} />
              <ul>
                <li>
                  Any type of legal entity (e.g. non-profit, faith-based,
                  educational) that shares a commitment to empower the Latino
                  community{" "}
                </li>

                <li>Is based in TN</li>

                <li>Serves the Latino community </li>

                <li>
                  Has at least 10 participants for the entirety of the
                  programming{" "}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <a
              href={Apply_now}
              target="_blank"
              rel="noopener noreferrer"
              className="CF_apply_button"
            >
              {" "}
              Apply Now{" "}
            </a>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <div className="faqs">
              <h1>FAQs</h1>
              <ol>
                <li>
                  Will we need to have recruited a group of participants prior
                  to applying for funding?
                  <br />
                  <br />
                  As part of the application process, you will submit a working
                  list of participants you plan to recruit or you have recruited
                  already. Unfortunately, if your organization does not have the
                  capacity to recruit a group of 10 participants prior to
                  submitting an application, the organization would be
                  ineligible for funding.{" "}
                </li>
                <br />
                <li>
                  Is there an application deadline? Will the community fund
                  operate on a yearly basis?
                  <br />
                  <br />
                  There is no application deadline. We operate on a first come,
                  first serve basis.
                  <br />
                  We are in the process of solidifying this detail. However, we
                  are actively fundraising on our end to have more partnerships
                  provide financial literacy programming to their communities.{" "}
                </li>
                <br />
                <li>
                  How many organizations will you fund?
                  <br />
                  <br />
                  We will provide funding to 10-40 organizations throughout TN.
                  In the near future, we plan to expand to GA, VA, SC, and NC.{" "}
                </li>
                <br />
                <li>
                  How will you determine how much funding to give to each
                  organization?
                  <br />
                  <br />
                  The amount of funding provided will depend on the
                  organization’s specific needs. For example, an organization’s
                  number of participants, level of access to technology, and
                  their existing financial literacy programming (if any) are
                  some of the factors that will determine the amount of funding
                  received.{" "}
                </li>
                <br />
                <li>
                  If our organization is already providing financial
                  programming, can we still receive funding?
                  <br />
                  <br />
                  Yes! It’s so great that you’re already doing this work for our
                  comunidades. The funding could be used to strengthen or simply
                  continue facilitating your existing programming. Please note
                  that we would vet your programming to ensure that it aligns
                  with ours.{" "}
                </li>
                <br />
                <li>
                  If we don’t serve Latino youth specifically but we serve the
                  broader Latino community in our area, could we still apply for
                  the funding?
                  <br />
                  <br />
                  Yes, we encourage you to apply. However, please note that our
                  programming is specifically tailored for that part of the
                  community.{" "}
                </li>
                <br />
                <li>
                  Our organization is only interested in the online learning
                  modules and not the funding–can we still apply?
                  <br />
                  <br />
                  Absolutely! You can indicate that in the application.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default CFEn;
