import React from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import privacy_policy from "../../assets/documents/Privacy_Policy_for_Nuestro_Loans.pdf"
import tnc from "../../assets/documents/Terms_and_Conditions_NUESTRO.docx"
import Footer from '../../components/footer/spanish';
import NavBar from '../../components/navbar/spanish';
import { Container } from 'react-bootstrap';
import "../../styles/style.css";

const LegalEs = () => {
  return (
    <div className="home_main" style={{display: 'flex', flexDirection: 'column', minHeight: '100vh',}}>
        <NavBar langTogglehref="/en/disclosures"/>
        <Container style={{ flex: 1 }}>
            
            
                
          <div className='container-wrapper'>    
            <h1 className="mt-3 mb-3 legal-title text-center grow-effect" style={{fontFamily: 'RockoUltraFLF', color:'rgb(232, 0, 120)', fontSize:'50px'}}>Divulgaciones de Nuestro Loans</h1>
<div className="legal ps-lg-5 ps-3 pt-3 slide-in-right" style={{color:'rgb(33, 67, 136)', fontFamily:'AvenirBold', fontSize:'30px'}}>
<a href={tnc} target="_blank" rel="noopener noreferrer"  style={{color:'rgb(33, 67, 136)', fontWeight:'600'}}>Términos y Condiciones</a><br/>    
<a href={privacy_policy} target="_blank" rel="noopener noreferrer"  style={{color:'rgb(33, 67, 136)', fontWeight:'600'}}>Política de privacidad</a><br/>
</div>
</div>  
    </Container>
    <Footer/>
    </div>
  )
}

export default LegalEs;