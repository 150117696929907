import React from "react";
import "../../styles/style.css";
import "../../styles/slidein.css";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/navbar/spanish";
import Footer from "../../components/footer/spanish";
import right_banner from "../../assets/images/CF_happy_girl.png";
import right_banner_2 from "../../assets/images/FL_fund_es_1-8.webp";
import CF1 from "../../assets/images/CFes1-8.webp";
import CF2 from "../../assets/images/CFes2-8.webp";
import CF3 from "../../assets/images/CFes3-8.webp";
import CF4 from "../../assets/images/CFes4-8.webp";
import CF5 from "../../assets/images/CFes5-8.webp";
import CF6 from "../../assets/images/CFes6-8.webp";
import Apply_now from "../../assets/documents/Nuestro - Community Fund Application.pdf";

const CFEs = () => {
  return (
    <div className="home_main">
      <Navbar langTogglehref="/en/community-fund" />
      <Container style={{ paddingTop: 0 }}>
        <Row>
          <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="CF_right_banner order-md-2 order-lg-2">
            <img
              alt="Nuestro community fund happy user"
              src={right_banner}
              className="left_banner_cf slide-in-left"
            />
          </Col>
          <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="left_CF_box order-md-1 order-lg-1">
            <div className="left_text_box slide-in-right">
              <p>
                El Fondo Comunitario Nuestro se estableció para cerrar la brecha
                de educación financiera que afecta a las comunidades latinas en
                el sureste de los Estados Unidos al proporcionar educación
                financiera fácil de entender y culturalmente sensible a los
                jóvenes latinos de la región.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ paddingTop: 0 }}>
        <Row>
        <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="CF_right_banner_box grow-effect order-md-2 order-lg-2"
          >
            <img
              alt="financial literacy for latinos"
              src={right_banner_2}
              className="CF_left_banner"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="left_CF_box_2 order-md-1 order-lg-1"
          >
            <div className="left_text_box_2 grow-effect">
              <p>
                Si sirves a la comunidad latina en Tennessee ¡Te invitamos a
                aplicar a nuestro Fondo Comunitario! Ofrecemo…
                <ul>
                  <li>Módulos de educación financiera en línea </li>

                  <li>
                    $ 500 - $ 2,000 en fondos para implementar la programación
                  </li>
                </ul>
              </p>
            </div>
          </Col>
          
        </Row>
      </Container>
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col>
            <h4 className="CF_culture">
              Nuestros módulos{" "}
              <span>
                biculturales
                <br /> y bilingües
              </span>{" "}
              en línea incluyen…
            </h4>
          </Col>
        </Row>
      </Container>
      <Container style={{ textAlign: "center"}}>
        <Row>
          <Col>
            <img alt="personal finance" src={CF1} width="150px" className="m-2" />
          </Col>
          <Col>
            <img alt="banking" src={CF2} width="150px" className="m-2" />
          </Col>
          <Col>
            <img alt="importance of credit" src={CF3} width="150px" className="m-2" />
          </Col>
          <Col>
            <img alt="DBT" src={CF4} width="150px" className="m-2" />
          </Col>
          <Col>
            <img alt="investing" src={CF5} width="150px" className="m-2" />
          </Col>
        </Row>
      </Container>
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col>
            <div className="eligible_text">
              <img alt="eligible for funds?" src={CF6} />
              <ul>
                <li>
                  Cualquier tipo de entidad legal (por ej., sin fines de lucro,
                  religiosa, educativa) que comparte el compromiso de empoderar
                  a la comunidad latina{" "}
                </li>

                <li>Tiene su sede en TN</li>

                <li>Sirve a la comunidad latina</li>

                <li>
                  Tiene al menos 10 participantes para la totalidad de la
                  programación.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <a
              href={Apply_now}
              target="_blank"
              rel="noopener noreferrer"
              className="CF_apply_button"
            >
              {" "}
              APLICA YA{" "}
            </a>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <div className="faqs">
              <h1>Preguntas Frecuentes</h1>
              <ol>
                <li>
                  ¿Debemos reclutar un grupo de participantes antes de solicitar
                  el financiamiento?
                  <br />
                  <br />
                  Como parte del proceso de solicitud, se enviará una lista de
                  trabajo de los participantes que planifica reclutar o que haya
                  reclutado. Desafortunadamente, si su organización no tiene la
                  capacidad de reclutar un grupo de 10 participantes antes de
                  enviar la solicitud, la organización no será elegible para
                  recibir financiamiento.{" "}
                </li>
                <br />
                <li>
                  ¿Hay una fecha límite para la solicitud?
                  <br />
                  <br />
                  ¿El Fondo Comunitario funcionará anualmente?
                  <br />
                  <br />
                  <ol>
                    <li>
                      No hay fecha límite para la solicitud. Operamos por orden
                      de llegada.
                    </li>

                    <li>Estamos en el proceso de solidificar este detalle.</li>
                  </ol>
                  <br />
                  Sin embargo, estamos activamente recaudando fondos para que
                  más asociaciones desarrollen programas de educación financiera
                  a sus comunidades.
                </li>
                <br />
                <li>
                  ¿Cuántas organizaciones financiarán?
                  <br />
                  <br />
                  Proporcionaremos fondos entre 10 a 40 organizaciones en todo
                  TN. En un futuro cercano, planificamos expandirnos a GA, VA,
                  SC y NC.
                </li>
                <br />
                <li>
                  ¿Cómo se determinará cuánto financiamiento será otorgado a
                  cada organización?
                  <br />
                  <br />
                  La cantidad de fondos proporcionados dependerá de las
                  necesidades específicas de la organización. Por ejemplo, la
                  cantidad de participantes de una organización, el nivel de
                  acceso a la tecnología y su programa de educación financiera
                  existente (si corresponde) son algunos de los factores que
                  determinarán la cantidad de financiamiento recibida.
                </li>
                <br />
                <li>
                  Si nuestra organización actualmente está proporcionando
                  programación financiera, ¿aún podríamos recibir
                  financiamiento?
                  <br />
                  <br />
                  Qué bueno que ya estés haciendo este trabajo por nuestras
                  comunidades. La financiación podría usarse para fortalecer o
                  simplemente continuar facilitando su programación existente.
                  Examinaremos su programación para asegurarnos de que se alinee
                  con la nuestra.
                </li>
                <br />
                <li>
                  Si no servimos específicamente a los jóvenes latinos, pero
                  servimos a la comunidad latina en general en nuestra área
                  ¿podríamos igualmente solicitar los fondos?
                  <br />
                  <br />
                  Sí, te animamos a aplicar. Tenga en cuenta que nuestra
                  programación está diseñada específicamente para esa parte de
                  la comunidad.
                </li>
                <br />
                <li>
                  Nuestra organización sólo está interesada en los módulos de
                  aprendizaje en línea y no en la financiación. ¿Aún podemos
                  presentar una solicitud?
                  <br />
                  <br />
                  ¡Absolutamente! Puede indicarlo en la solicitud.
                  <br />
                  <br />
                </li>
              </ol>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  Nuestro está comprometido a asegurar que nuestras comunidades
                  profundicen en su educación financiera, mejoren sus hábitos
                  financieros y construyan riqueza generacional.
                  <br />
                  <br />
                  Estamos emocionados de asociarnos con usted para avanzar en
                  esta misión.
                  <br />
                  <br />
                  ¡Sí se puede!
                </li>
              </ul>
              <br />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default CFEs;
