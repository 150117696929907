import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/dp.png";
import b2 from "../../../assets/images/rb2.webp";
import b3 from "../../../assets/images/rb3.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog1En = () => {
    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };
    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/nuestro-features-part-3-enrico-lopez-yañez-principal-pops-director-at-the-nashville-symphony"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: '13px' }}>
                  <img src={dp} width="40px" alt="Author DP" /> &nbsp;
                  Jane Flautt &nbsp;&bull;&nbsp; Dec 8, 2023 &nbsp;&bull;&nbsp; 4 min read
                </p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville Symphony</h1>
                    <br/>
                    <p>Over a Zoom call from Indianapolis, where he was conducting a music of Harry Potter show, Enrico Lopez-Yañez delved into his busy yet rewarding life of conducting at symphonies across America. Although Enrico is primarily based in Nashville, where he has been the Principal Pops Conductor at the Nashville Symphony for six years, he also is the Principal Pops Conductor at the Pacific Symphony, the Principal Conductor at the Dallas Symphony, and a guest conductor and writer for other orchestras across the country. Most recently, Enrico has been selected as Detroit Symphony’s next Principal Pops Conductor for the 2024-25 season.
<br/>
<br/>
Enrico has more experience in the world of classical music and performance than most — with his father being an opera singer and his mother a professional pianist, the world of opera and orchestras is in his blood. “I’m very lucky that I had two parents who were in that industry and were willing to be understanding of it and supportive of it,” Enrico said. Especially entering a career in the music world, Enrico recognized that the support he got from his family was unusual. Specifically, Enrico’s father encouraged his son in the same way he himself was supported by his family when he decided to leave Mexico and his engineering career to study music in the U.S. in his late 20s. His father’s story, Enrico explained, inspired him to pursue music despite the perception that this industry is an unstable profession to get into. 
<br/>
<br/>
In fact, Enrico emphasized that any career has risk to it — there’s no career choice that will automatically guarantee job security, and so it’s important to pursue what you’re passionate about. And, “It’s ok not to know what you want to do, and until you figure it out, try everything,” Enrico said, and his own career trajectory reflects this advice. After getting his bachelor’s from UCLA and his master’s in music from the University of Maryland, Enrico initially wanted to conduct in the opera world and just happened to get more involved in Pops orchestra right out of school at the Omaha Symphony. From there, he “realized that what a Pops conductor does was really appealing to me because it was combining all my different loves from childhood into one job.” Growing up listening to and playing all different genres of music, from jazz to rock to classical, Enrico embraced the flexibility and breadth that Pops orchestra — which combines popular music, show tunes, and classical music — has to offer him as a conductor.
<br/>
<br/>
Less than 10 years after he finished his master’s and started conducting, Enrico has made a name for himself in the conducting world and beyond. He has garnered awards and recognition such as the 2023 Mexicanos Distinguidos Award, which is given by the Mexican government to Mexican citizens living abroad who have excelled in their careers. Along with his outstanding successes and accolades so early in his career, Enrico remains committed to improving the music world he is a part of — in particular, when it comes to education and outreach. In general, Enrico wants to see educational programs within the symphony world expand across the country, such as one at the Dallas Symphony which serves underrepresented communities and provides instruments and weekly lessons to students to help them become involved in orchestral music. 
<br/>
<br/>
Beyond his conducting work, Enrico is also the co-founder of Symphonica Productions, a company that builds packaged shows for orchestras that are more educational, family-oriented, and interactive. Since some orchestras don’t have the budget to create or produce shows that are targeted to different kinds of audiences, Enrico’s company steps in and creates packaged shows that are ready-made for orchestras; the music, costumes, actors, and other materials are sent to symphonies who want to host these kinds of performances. This type of interactive show is especially great for younger kids, Enrico explained, to get them interested in orchestral music by creating colorful, energetic, and interactive experiences for them.
<br/>
<br/>
In other productions and performances that he’s a part of, Enrico also takes inspiration from his family history, striving to “include the Hispanic community more and more in the classical music realm in the U.S.” One way that Enrico and the Nashville Symphony melded the worlds of traditional orchestra and Mexican music was Latin Fire, an event at Plaza Mariachi that combined opera music like Josh Groban and Italian art songs with Mexican pop like Juan Gabriel and mariachi music. Latino musical voices like the ones represented in Latin Fire are not often included in symphony productions, Enrico said. By adding more musical variety to orchestras, this event is an important step toward diversifying the symphony world in Enrico’s eyes. Since “orchestras stem from a tradition of very Eurocentric composition,” Enrico explained, he heavily supports efforts to diversify the world of orchestras, from the musicians, conductors, and audience to the types of composers whose music they perform. 
<br/>
<br/>
To learn more about the Nashville Symphony and to find their upcoming schedule of concerts, you can visit the link here.
</p>
                </Col>
            </Row>
            <Row>
<img src={b1}/>
<p>Photo courtesy of</p>
</Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b2}/>
    </Col>
    <Col>
    <img src={b3}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 1: Luis Pedraza, Resource Coordinator at TIRRC

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog1En;