import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog10Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/what-are-common-types-of-debt"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jun 15, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>¿Cuáles son los tipos comunes de deuda?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Recuerde que la deuda es dinero prestado que debe devolverse, por lo general, con intereses. A continuación se presentan los tipos de deuda más comunes:
                    <br/>
                    <br/>
<b>Préstamos</b>
<br/>
Un préstamo es una cantidad fija de dinero que se pide prestado por una variedad de razones. Puede ser un préstamo de automóvil para comprar uno o préstamos estudiantiles para pagar la educación superior.
<br/>
                    <br/>

                    Independientemente del tipo de préstamo que reciba, debe pagarlo en una fecha determinada. Además, cada préstamo tiene términos específicos que debe cumplir y tiene una cantidad fija de interés que debe ser pagada.
<br/>
                    <br/>

<b>Préstamos personales</b>
<br/>
Un préstamo personal es dinero prestado que se puede utilizar para casi cualquier compra importante. Por ejemplo, puede obtener un préstamo personal para comprar un bote, cubrir una boda o unas vacaciones.
<br/>
                    <br/>

<b>Hipotecas</b> 
<br/>
Son deudas que se utilizan para comprar bienes inmuebles. Esta deuda se paga durante largos períodos de tiempo, generalmente entre 15 y 30 años, y tiende a ser la mayor cantidad de deuda que las personas asumen en sus vidas.
<br/>
                    <br/>

                    La deuda de tarjetas de crédito es otro tipo común de deuda. Si desea obtener más información sobre el crédito, consulte nuestra publicación de blog anterior titulada "La importancia de la creación de crédito".
<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>comunidad </b>
                    <b>Credito </b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog10Es;