import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/dp.png";
import b3 from "../../../assets/images/rb3.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog2En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };
    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/nuestro-features-part-2-elizabeth-narváez-vega-college-success-coach-at-conexión-américas"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Jane Flautt &nbsp;&bull;&nbsp; 
Oct 30, 2023
 &nbsp;&bull;&nbsp; 3 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión Américas</h1>
                    <br/>
                    <p>Elizabeth Narváez-Vega is helping first-gen college students on the path to success at Conexión Américas — Here’s how she got there and what advice she gives to other first-gen students. 
                    <br/>
<br/>
Elizabeth Narváez-Vega knew she wanted to be a teacher since she was young — she always pretended to be the teacher during playtime with her siblings when they were kids. And while her current job isn’t exactly the same as what she used to imagine for herself, Liz’s day-to-day is dedicated to helping young people get the education experience they deserve. As a college success coach at Conexión Américas, Liz works with all students who go through Conexión’s high school programs and continues to support them while they’re in college, which involves everything from explaining how to submit a FAFSA to showing students what resources are available to them at their campuses. All of Liz’s students are first-generation college students, and as a first-gen graduate herself, she draws from her own experience as a daughter of immigrants to assist the students coming to Conexión for support.
<br/>
<br/>
Liz’s parents immigrated to the U.S. almost 30 years ago, and Liz grew up in Nashville, although she frequently went back to Mexico a few times a year since most of her extended family was there. These trips, along with her parents’ influence, encouraged her to remain deeply rooted to her Mexican heritage, Liz shared. When she was growing up in the early 2000s, the Latino immigrant community was much smaller in Middle Tennessee, and Nashville was a lot less welcoming, she explained. There was not as much understanding of the immigrant experience or even what “first-generation” meant. Liz had “a complicated relationship [with her Latina identity] growing up in the south when there wasn’t a lot of representation of Latinidad,” she said. Liz also recalled certain messages in school that pushed classrooms to be English-only, and because of this she thought speaking Spanish at school and outside her home was “shameful.” But her parents pushed back against this — they encouraged her to embrace her bilingualism and see it as an asset rather than something to be embarrassed about. 
<br/>
<br/>
As Liz progressed through high school, she got involved with Conexión’s programming, and it was this experience that inspired her to search for job openings at Conexión after she graduated from Belmont University in 2019. After pivoting to study not just education but also social work at Belmont, Liz especially was drawn to Conexión since she would get to work with the same students over the years, instead of just on a yearly cycle if she had pursued classroom teaching. Now, as Liz continues to work with students in Metro Nashville Public Schools and at universities, she has seen positive changes within these systems not only to be more inclusive of bilingual students and non-native English speakers, but also to increase diversity within both the student body and the faculty. Liz also has noticed more resources popping up on college campuses created specifically for first-gen students. When she was at Belmont, she explained that first-gen resources were often lumped in with international student resources, which was a problem since these two groups of students sometimes require different kinds of support. 
<br/>
<br/>
For her current students, outside of academic questions, Liz emphasized that some of the most common questions she is asked are about loans and building credit. She often directs students to other organizations that are specifically geared toward financial advice, explaining that “one of the biggest barriers and hurdles for first-gen students is financial literacy… [such as] understanding the type of loans you’re taking out.” Asked what advice she would give students or recent graduates, she highlighted the importance of credit. “Credit is the basis for a lot of the financial moves you want to make,” she said, and Liz regrets not starting to build credit while she was still in college. Coming from a household that, like many first-generation immigrant households, emphasized saving, it took Liz some time to become comfortable with going into debt in order to eventually build credit and financial stability. Another key asset Liz recommends that young folks take advantage of is your network — her network of connections is how Liz found her job at Conexión Américas that she loves. 
<br/>
<br/>
More generally, Liz also expressed her support for college students, particularly first-gen, who might feel overwhelmed as they enter college and try to figure out their career goals. To first-gen students experiencing self-doubt, imposter syndrome, or career anxiety, Liz wanted to remind them that first of all, you’re not bound to the first job you get out of school, and second, that “you’ve done the work to be there [and] you are deserving” of your education and any career path you choose to pursue.
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b3}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 1: Luis Pedraza, Resource Coordinator at TIRRC

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog2En;