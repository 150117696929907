import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog11En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/what-is-debt"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jun 8, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>What is debt?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    If you are planning to…

<ul>
<li>Pursue higher education</li> 
<li>Buy a car</li>
<li>Purchase a house </li>
<li>Start a business </li>

</ul>
You need to understand debt first! Debt is money borrowed that has to be paid back usually with interest. It can have a fixed payment due date or it can be revolving. There are different types of debt. 
<br/>
                    <br/>

There is secured debt, or debt that is collateralized. Collateralized means that borrowers have to pledge something as security (e.g. money) for the repayment of a loan, and if the loan isn’t paid, the lender will take the security. Examples of secured debt include car loans, mortgages, and secured credit cards. 

<br/>
                    <br/>
On the other hand, unsecured debt does not require any collateral. For this type of debt, the lender decides to grant the loan or credit based on the borrower’s credit score, and it usually has higher interest rates than secured loans. Examples include credit cards and personal loans. 
<br/>
                    <br/>

Common Forms of debt include:
<ul>
<li>Mortgages</li>
<li>Auto loans</li>
<li>Student debt</li>
<li>Personal loans </li>
<li>Credit Card </li>
</ul>
In the next blog posts, you will learn more about each type of debt, so please stay tuned!
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog11En;