import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog5Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };


    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/where-can-i-start-investing"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jul 24, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                    <h1>¿Dónde puedo comenzar a invertir?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Sabemos que comenzar a invertir puede ser abrumador, pero en esta publicación, aprenderá cómo comenzar.

                    <br/>
                    <br/>
Puede comenzar a invertir a través de cuentas de jubilación (las más populares son 401k y 403b, son cuentas de jubilación individuales y cuentas de corretaje.

    <br/>
                    <br/>

Un 401k es una cuenta basada en el empleador que le permite que un porcentaje de su cheque de pago vaya directamente a una cuenta de inversión que su empleador también puede aceptar igualar o pagar parte de la contribución total.

<br/>
                    <br/>
Las cuentas de Retiro Individual (o también conocidas como IRA), por otro lado, varían dependiendo de cuándo decida pagar impuestos sobre el dinero agregado.

<br/>
                    <br/>
Para las cuentas IRA tradicionales, no paga impuestos en este momento, hasta que su dinero se retire al jubilarse.

<br/>
                    <br/>
Mientras que con las cuentas IRA Roth, paga impuestos sobre el dinero que agrega y ninguno cuando lo retira. La contribución máxima para ambas cuentas en un año calendario dado es de $6,500.
<br/>
                    <br/>

Le recomendamos que evalúe estas cuentas y proceda con la que mejor se adapte a su situación financiera.

<br/>
                    <br/>
Recuerde, cuanto antes empiece a invertir en su futuro, más interés ganará su dinero.

<br/>
                    <br/>
¡Sí se puede, mi gente!
<br/>
                    <br/>
                    <b>banco  </b>
                    <b>ahorro </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>debito </b>
                    <b>credit </b>
                    <b>deuda </b>
                    <b>comunidad </b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog5Es;