import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog14En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/where-should-i-store-my-dinero"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
May 18, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
               
                    <h1>Where Should I Store My Dinero?</h1>
                    <br/>
                    <p>
                    You should store your dinero in a FDIC-insured bank! 
                    <br/>
                    <br/>
FDIC stands for Federal Deposit Insurance Corporation, and it is a federal agency that protects a customer’s money in a bank in case of a bank failure or theft. The maximum insurable amount is $250,000 per depositor. This means that if you have $200,000 stored in a FDIC-insured bank and there is a bank failure, you’ll still be given your $200,000. Therefore, storing your dinero in a FDIC-insured bank is completely safe and secured. 
<br/>
                    <br/>

In opening an FDIC-insured bank account, you’ll gain access to a checking and savings account. A checking account is one that allows you to make deposits and withdrawals. A savings account, on the other hand, is one where you can store money that you want to use in the future. 
<br/>
                    <br/>

When you store your money in a bank, there are no fees to deposit your money in your account and you’re able to protect your money from error and fraud. You’re also able to have full control of your money and more easily manage your money. For instance, you’d be able to…
<ul>
<li>Track your money more easily </li>
<li>Pay your bills all in one place, from wherever you are</li>
<li>Send money to family and friends  </li>
<li>Get money quicker via direct deposit (e.g. paychecks from your employer)</li>
<li>Bank statement, which is a  record of the activity on your account </li>
</ul>
As you can see, there are so many advantages to banking, so here’s to keeping your dinero safe in a  bank!
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog14En;