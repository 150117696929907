import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog17En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };


    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/el-poder-del-budget"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Apr 27, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>El Poder del Budget</h1>
                    <br/>
                    <p>
                    ¡Hola! 

                    <br/>
                    <br/>
It’s very important that you have a budget because having one is foundational for your money journey. 

<br/>
                    <br/>
<b>What is a budget?</b>
<br/>
A budget is simply a tool to help you organize your money 🤯It is under your control and you can make it as restrictive or as loose as possible. It doesn’t have to deprive you! 🥳
<br/>
<br/>
<b>Why do you need to have a budget?</b> 
<br/>
A budget helps you become more intentional with your spending so you can spend on things & experiences that bring you joy while still achieving your financial goals 🥳
<br/>
                    <br/>

<b>How can I start a budget?</b> 
<br/>
We recommend that you start with a simple budget. You can try the 50/30/20 budget. This is how this budget is broken down:
<br/>
                    <br/>

50% of your money should go to needs
<br/>
30% should go to wants 
<br/>
20% should go to savings
<br/>
                    <br/>

So here’s to budgeting because you deserve to achieve your financial goals! 
<br/>
                    <br/>

¡Pa’lante! 


<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Finance </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog17En;