import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/dp.png";
import b2 from "../../../assets/images/rb2.webp";
import b3 from "../../../assets/images/rb3.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog1Es = () => {
    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };
    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/nuestro-features-part-3-enrico-lopez-yañez-principal-pops-director-at-the-nashville-symphony"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: '13px' }}>
                  <img src={dp} width="40px" alt="Author DP" /> &nbsp;
                  Jane Flautt &nbsp;&bull;&nbsp; Dec 8, 2023 &nbsp;&bull;&nbsp; 4 Min. de lectura
                </p>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de Nashville</h1>
                    <br/>
                    <p>Durante una llamada de Zoom desde Indianápolis, donde estaba dirigiendo una música del espectáculo de Harry Potter, Enrico Lopez-Yañez profundizó en su ajetreada pero gratificante vida de dirigir sinfonías en todo Estados Unidos. Aunque Enrico vive principalmente en Nashville, donde ha sido el Director Principal de Pops de la Sinfónica de Nashville durante seis años, también es el Director Principal de Pops de la Sinfónica del Pacífico, el Director Principal de la Sinfónica de Dallas y director invitado y escritor de otras orquestas de todo el país. Más recientemente, Enrico ha sido seleccionado como el próximo<a href="https://www.linkedin.com/feed/update/urn:li:activity:7124395932598616064/" target="_blank" rel="noopener noreferrer"> director principal de Detroit Symphony</a> para la temporada 2024-25.
<br/>
<br/>
Enrico tiene más experiencia en el mundo de la música clásica y la interpretación que la mayoría: con su padre un cantante de ópera y su madre una pianista profesional, el mundo de la ópera y las orquestas está en su sangre. "Tengo mucha suerte de tener dos padres que estaban en esa industria y estaban dispuestos a entenderla y apoyarla", dijo Enrico. Especialmente al entrar en una carrera en el mundo de la música, Enrico reconoció que el apoyo que recibía de su familia era inusual. Específicamente, el padre de Enrico animó a su hijo de la misma manera que él mismo fue apoyado por su familia cuando decidió dejar México y su carrera de ingeniería para estudiar música en los Estados Unidos a finales de sus 20 años. La historia de su padre, explicó Enrico, lo inspiró a dedicarse a la música a pesar de la percepción de que esta industria es una profesión inestable en la que entrar.
<br/>
<br/>
De hecho, Enrico hizo hincapié en que cualquier carrera tiene un riesgo: no hay una opción de carrera que garantice automáticamente la seguridad laboral, por lo que es importante perseguir lo que te apasiona. Y, "está bien no saber lo que quieres hacer, y hasta que lo averigües, pruébalo todo", dijo Enrico, y su propia trayectoria profesional refleja este consejo. Después de obtener su licenciatura de UCLA y su maestría en música de la Universidad de Maryland, Enrico inicialmente quería dirigir en el mundo de la ópera y se involucró más en la orquesta Pops justo después de la escuela en la Sinfónica de Omaha. A partir de ahí, "me di cuenta de que lo que hace un director de Pops era realmente atractivo para mí porque estaba combinando todos mis diferentes amores de la infancia en un solo trabajo". Al crecer escuchando y tocando todos los diferentes géneros de música, desde el jazz hasta el rock y la música clásica, Enrico abrazó la flexibilidad y amplitud que la orquesta Pops, que combina música popular, melodías de espectáculo y música clásica, tiene para ofrecerle como director de orquesta.
<br/>
<br/>
Menos de 10 años después de terminar su maestría y comenzar a dirigir, Enrico se ha hecho un nombre en el mundo de la dirección y más allá. Ha obtenido premios y reconocimientos como<a href="https://pacificsymphony.blog/2023/09/14/mexican-government-honors-enrico-lopez-yanez/" target="_blank" rel="noopener noreferrer"> el Premio Mexicanos Distinguidos 2023</a>, que es otorgado por el gobierno mexicano a los ciudadanos mexicanos que viven en el extranjero y que han sobresalido en sus carreras. Junto con sus destacados éxitos y elogios tan temprano en su carrera, Enrico sigue comprometido con la mejora del mundo de la música del que forma parte, en particular, cuando se trata de educación y divulgación. En general, Enrico quiere ver que los programas educativos dentro del mundo de la sinfónica se expanden por todo el país, como uno en la Sinfónica de Dallas, que sirve a comunidades subrepresentadas y proporciona instrumentos y lecciones semanales a los estudiantes para ayudarlos a involucrarse en la música orquestal.
<br/>
<br/>
Además de su trabajo de dirección, Enrico también es el cofundador de Symphonica Productions, una empresa que crea espectáculos para orquestas que son más educativas, orientadas a la familia e interactivas. Dado que algunas orquestas no tienen el presupuesto para crear o producir espectáculos que estén dirigidos a diferentes tipos de audiencias, la compañía de Enrico interviene y crea espectáculos que están listos para orquestas; la música, los trajes, los actores y otros materiales se envían a las sinfónicas que quieren albergar este tipo de actuaciones. Este tipo de espectáculo interactivo es especialmente bueno para los niños más pequeños, explicó Enrico, para que se interesen por la música orquestal creando experiencias coloridas, enérgicas e interactivas para ellos.
<br/>
<br/>
En otras producciones y actuaciones de las que forma parte, Enrico también se inspira en su historia familiar, esforzándose por "incluir a la comunidad hispana cada vez más en el ámbito de la música clásica en los Estados Unidos". Una forma en que Enrico y la Sinfónica de Nashville fusionaron los mundos de la orquesta tradicional y la música mexicana fue Latin Fire, un evento en Plaza Mariachi que combinaba música de ópera como Josh Groban y canciones de arte italiano con música pop mexicano como Juan Gabriel y mariachi. Las voces musicales latinas como las representadas en Latin Fire no se incluyen a menudo en las producciones sinfónicas, dijo Enrico. Al añadir más variedad musical a las orquestas, este evento es un paso importante hacia la diversificación del mundo sinfónico a los ojos de Enrico. Dado que "las orquestas provienen de una tradición de composición muy eurocéntrica", explicó Enrico, apoya en gran medida los esfuerzos para diversificar el mundo de las orquestas, desde los músicos, los directores y el público hasta los tipos de compositores cuya música interpretan.
<br/>
<br/>
Para obtener más información sobre la Sinfónica de Nashville y encontrar su próximo programa de conciertos, puede visitar el enlace<a href="https://www.nashvillesymphony.org/tickets/" target="_blank" rel="noopener noreferrer"> aquí</a> .
</p>
                </Col>
            </Row>
            <Row>
<img src={b1}/>
<p>Photo courtesy of</p>
</Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b2}/>
    </Col>
    <Col>
    <img src={b3}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña, 1ra Parte: Luís Pedraza, Coordinador de Recursos de TIRRC

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog1Es;