import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog7En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/secure-your-financial-futuro-with-investing"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jul 11, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Secure Your Financial Futuro with Investing</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    One of the ways our comunidades can flourish financially is by investing, which is the act of buying assets that can generate profit in the future. 
                    <br/>
                    <br/>
You might be wondering: why should I invest for the future when we are currently in need of money? If you want to… 

<ul>
<li>Retire</li>
<li>Build wealth and/or </li>
<li>Pass down wealth to loved ones</li>
</ul>

<center><p style={{fontStyle:'italic'}}>You need to learn about and begin investing!</p></center>

You also need to learn about and begin investing because of inflation, which is the rise of prices and the decline of your purchasing power over time. If you don’t invest, your money will lose value to inflation. Meanwhile, when you invest, you’re able to take advantage of something known as compound interest. 
<br/>
                    <br/>

Compound interest is essentially interest earned on interest, meaning that the more you invest, and the earlier you do so, the more interest your money will gain. The best part of this is that once you invest, your money will grow without you having to do anything.
<br/>
                    <br/>

In the next blog posts, you will learn more about the stock market, how it works to make your money grow, and how to get started with investing. 
<br/>
                    <br/>

Here’s to building wealth through investing because ¡sí se puede, mijente! 


<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog7En;