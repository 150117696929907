import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog13En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/help-what-are-banking-transactions"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
May 24, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
               
                    <h1>Help! What are Banking Transactions?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    If you have no idea about basic banking terminology that you feel like you should know about, you’re at the right place 🙂 This article will explain four banking terms: ACH Transfers, Wire Transfers, ATMs, and Peer-to-Peer (P2P) Transactions.
                    <br/>
                    <br/>

<p style={{textDecoration:'underline'}}>ACH Transfers</p>

These are electronic transfers that happen between banks and credit unions. The transfers need to be verified, are only for domestic use, and are typically free or low cost. Examples include direct deposit and paying your bills online. 

<br/>
                    <br/>
                    <p style={{textDecoration:'underline'}}>Wire Transfers</p>

They consist of moving money from one bank to another. These transfers do not need to be verified, are much quicker than ACH transfers, and can be sent internationally. They also cost a fee.

<br/>
                    <br/>
                    <p style={{textDecoration:'underline'}}>ATMs</p>

These are specialized computers that allow you to complete banking transactions without talking to a bank representative. They are convenient in that they allow you to do quick self-service transactions like deposits and withdrawing cash. 
<br/>
                    <br/>

                    <p style={{textDecoration:'underline'}}>Peer-to-Peer (P2P) Transactions</p>

These transactions are money that you send directly to another person through payment systems like Venmo and Cash App. They are a type of ACH transfer, they keep your bank account details private, and all you need to perform them is the recipient’s email address or phone number. 

<br/>
                    <br/>
Hopefully, you now have a better understanding of these terms and feel more confident with banking! Because you deserve to keep your dinero safe and use it conveniently.
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog13En;