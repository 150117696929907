import React from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import nuestrowallet from "../../assets/images/Productos1.webp"
import nuestroloans from "../../assets/images/Productos2.webp"
import financialliteracy from "../../assets/images/Productos3.webp"
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterEn from '../../components/footer/spanish';
import Navbar from '../../components/navbar/spanish';
import "../../styles/style.css";
import "../../styles/slidein.css"

const ProductEs = () => {
  return (
    <div style={{overflow:'hidden', backgroundColor:'rgb(33, 67, 130)'}}>
        <Navbar langTogglehref="/en/products"/>
        <div style={{backgroundColor:'#ffffff'}}>
        <Container className="pt-5 pb-1 mt-4 products" fluid>
          <Row>
            <Col sm={12} md={4} className="p-3">
              <a target="_blank" rel="noopener noreferrer" href="https://www.nuestrowallet.com"><img alt="nuestro banking services" src={nuestrowallet} width="250px" className="circular-grow"/></a>
            </Col>
            <Col sm={12} md={4} className="p-3">
            <a target="_blank" rel="noopener noreferrer" href="https://www.nuestro.loans"><img alt="nuestro accessible loans" src={nuestroloans} width="250px" className="circular-grow"/></a>
            </Col>
            <Col sm={12} md={4} className="p-3">
            <a target="_blank" rel="noopener noreferrer" href="/en/financial-literacy"><img alt="financial literacy" src={financialliteracy} width="250px" className="circular-grow"/></a>
            </Col>
          </Row>
        </Container>
        <Container className="products_description" fluid>
          <Row>
            <Col sm={0} md={6}>
            </Col>
            <Col sm={12} md={6}>
                <h1 className="slide-in-left">Ahora a prosperar financieramente en comunidad porque…<span>¡Sí se puede!</span></h1>
            </Col>
          </Row>
        </Container>
        </div>
    <FooterEn/>
    </div>
  )
}

export default ProductEs;