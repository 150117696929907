import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";

const Blog12En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/let-s-talk-about-credit-and-debit-cards"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jun 23, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Let’s Talk About Credit and Debit Cards</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    As you may know, there are different types of bank cards. In this post, we will provide you with key differences between two major bank cards: Credit and Debit Cards. 

                    <br/>
                    <br/>
                    <p style={{textDecoration:'underline'}}>Credit Card</p>

This is money you borrow from the card issuer to make purchases or withdraw cash. The money you pay back is subject to an interest rate. Credit cards have a credit limit, which is the max amount of money you can borrow. They may also charge fees, and you can gain rewards for traveling or cash back, depending on the type of card you issue. 

<br/>
                    <br/>
<p style={{textDecoration:'underline'}}>Debit Card</p>

This card allows you to spend money by using the funds available from your bank account. It has few or no fees unless you spend more than what you have available in your account, which is known as an overdraft fee. Unlike credit cards, with many debit cards, you are not able to gain rewards or build credit. 

<br/>
                    <br/>
<p style={{textDecoration:'underline'}}>Physical vs Virtual Cards</p>

Both debit and credit cards are available physically and virtually. These cards consist of a 16-digit card number, unique Card Verification Value (CVV) code, and an expiration date. Virtual cards only exist in a digital format. You may use a virtual card, just like you would with a physical card. 
<br/>
                    <br/>

Now that you know the difference between these two cards, you can use them more effectively to take control of your finances.

<br/>
                    <br/>
¡Sí se puede
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog12En;