import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";


const Blog18En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/3-tips-to-take-care-of-nuestro-dinero"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Apr 20, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>3 Tips to Take Care of Nuestro Dinero</h1>
                    <br/>
                    <p>
                    Dinero is something that we all know and talk about because it’s super important in our lives. And although we all know that we need dinero to live, most of us were never taught how to manage it effectively. 
                    <br/>
                    <br/>

If you want to better manage your finances, here are 3 tips you can implement:

<br/>
<br/>
<b>Organize your dinero </b>
<br/>
Sometimes we overspend, feel stressed about our money, and/or do not meet our financial goals because we have disorganized finances. You can use a budget to begin organizing your finances.

<br/>
                    <br/>
<b>Become aware of your money habits</b>
<br/>
We should strive to develop money habits that serve us so we can improve our quality of life, achieve our financial goals, and build wealth. You can begin to identify your money habits by tracking your expenses. 
<br/>
                    <br/>

<b>Be intentional with your money</b>
<br/>
A really important money habit to develop is to become intentional with your money so you can spend only on things that you need to live, that you value, and that bring you joy. 

<br/>
                    <br/>
We hope that with these three dinero tips, you are able to take control of your finances and achieve your financial goals porque…
<br/>
                    <br/>

¡Si se puede! 
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog18En;