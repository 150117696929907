import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/dp.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog3En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/nuestro-features-part-1-luis-pedraza-resource-coordinator-at-tirrc"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Jane Flautt &nbsp;&bull;&nbsp; 
Sep 21, 2023
 &nbsp;&bull;&nbsp; 3 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Nuestro Features, Part 1: Luis Pedraza, Resource Coordinator at TIRRC</h1>
                    <br/>
                    <p>
                    Luis Pedraza didn’t expect he would end up where he is now. From an early age, his dream was to become an immigration lawyer, representing cases and making a difference on an individual level. It wasn’t until he asked his mentor for a letter of recommendation for law school and she told him she didn’t think law school was the path for him that something clicked: maybe he was meant for a different kind of career. Luis’s volunteer work with nonprofits while at University of Tennessee, Knoxville, along with his passion for policy reform, led him to the Tennessee Immigrant and Refugee Rights Coalition, where he is now a Resource Coordinator. Specifically, Luis does COVID-19 education and access work, where he is able to pull from previous experience as a contact tracer to give folks who come to TIRRC holistic support and resources. 
                    <br/>
<br/>
One of the main reasons he started to lean into the nonprofit space is that places like TIRRC are looking at the bigger picture of why folks are coming to them in the first place. Services are just part of what TIRRC does, Luis explained. The larger goal is to reform the systems and their flaws so no one needs these services one day, and Luis is passionate about TIRRC’s goal of systemic change on a personal level as well, having spent most of his life in Tennessee. Being a Latino in the South is “a unique experience,” Luis explained, and has been frustrating at times, especially because large national nonprofits often overlook Tennessee and the South as not being “open-minded places,” which only hurts folks like immigrant communities who are already underserved by local and state policy-makers. 
<br/>
<br/>
Luis describes himself as first-generation born in the U.S. and second-generation Latino living in the U.S., as his parents were from Mexico originally and he was born in California before moving to Tennessee when he was very young. Growing up, he remembers a pleasant childhood, but as he got older, he became more aware of the challenges his family faced as mixed-status — some members of his family are undocumented, and at one point his family even dealt with a deportation incident. Seeing these difficulties affecting him and his family, Luis wanted to be a source of support to his family and his broader community, which he sees as a common mindset for second-generation Latino folks. 
<br/>
<br/>
“I see myself as an uplink” for those around him, Luis said, like his parents and older generations who did not have the same access to resources and opportunities as he does. Luis became the first person in his family to finish college, and he was able to get a job he values in a place he loves, things that his parents weren’t able to accomplish. As a second-generation Latino immigrant, Luis explained how he and many other folks like him feel pressure to be constantly looking out for the rest of their family and to plan for the future — in particular, the financial future — of their parents and families. Growing up with that kind of responsibility on your shoulders can be overwhelming, so some advice Luis has for folks who might be struggling with anxiety for themselves and their families is to be forgiving of yourself and your limits: burnout is real, and you have to take care of yourself before you take of others, Luis said. 
<br/>
<br/>
Luis specifically advised folks like him, who might have grown up around a family who didn’t discuss finances and money at all, to lean into financial education and growth. He used to feel embarrassed as an adult who didn’t have a lot of financial literacy or confidence until he started to ask more questions and seek out resources to educate himself. The first person you ask may not be able to answer your question, Luis explained, but they probably know someone who can help you — however, you have to initiate that conversation and feel uncomfortable before any change can happen. Now, at 26, Luis is less intimidated by the mechanics behind the financial world, and he wants people to know that, yes, you will feel daunted by investments and 401(k)s and credit scores at first, but once you start asking questions and learning more, you will gain confidence in yourself just like he did. 
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog3En;