import React from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterEn from '../../components/footer/spanish';
import NavbarEn from '../../components/navbar/spanish';
import "../../styles/teamcard.css";
import diego from "../../assets/images/team/diego.jpg";
import gustavo from "../../assets/images/team/gustavo.jpg";
import miguel from "../../assets/images/team/miguel.jpg";
import nicholas from "../../assets/images/team/nicholas.jpg";
import veronica from "../../assets/images/team/veronica.jpg";
import hector from "../../assets/images/team/hector.jpg";
import noelia from "../../assets/images/team/noelia.jpg";

const TeamEs = () => {
  return (
    <Container fluid style={{ overflow: 'hidden', backgroundColor: 'rgb(33, 67, 130)' }}>
      <NavbarEn langToggleHref="/en/team" />
      <Container style={{ textAlign: 'center' }}>
        <Row>
          <Col>
            <h1 style={{ fontFamily: 'RockoUltraFLF', color: 'rgb(232, 0, 120)', textAlign: 'left', margin: '20px 40px', fontSize: '70px' }}>
              MEET OUR TEAM
            </h1>
          </Col>
        </Row>
        <Row style={{ margin: '30px 0' }}>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={diego} />
                  </div>
                </div>
              </div>
              <div class="text-container">
                <h3>Diego Eguiarte</h3>
                <h6>Fundador</h6>
                <div>
                  <p>Como nuestro fundador, su visión es empoderar a la comunidad mientras emprenden en su vida financiera, brindándoles mayores oportunidades de éxito.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={nicholas} />
                  </div>
                </div>
              </div>
              <div class="text-container">
                <h3>Nick Wijnberg</h3>
                <h6>Finanzas</h6>
                <div>
                  <p>Nick, con experiencia en consultoría financiera, es clave para aportar ideas al análisis financiero, la estrategia y la planificación.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={veronica} />
                  </div>
                </div>
              </div>
              <div class="text-container">
                <h3>Veronica Uribe</h3>
                <h6>Credito</h6>
                <div>
                  <p>Con amplia experiencia en la banca para latinos en Estados Unidos, ella defiende la diversidad, la comprensión cultural y la eliminación de las barreras lingüísticas en los servicios financieros.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: '30px 0' }}>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={noelia} />
                  </div>
                </div>
              </div>
              <div class="text-container-2">
                <h3>Noelia Fuentes</h3>
                <h6>Cliente En la Empresa</h6>
                <div>
                  <p>Noelia es una defensora de la justicia social, participa activamente en causas pro-inmigrantes y trabaja con familias hispanas a través de varias organizaciones. Nadie entiende a nuestros clientes mejor que ella.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={miguel} />
                  </div>
                </div>
              </div>
              <div class="text-container-2">
                <h3>Miguel Vega</h3>
                <h6>Crecimiento</h6>
                <div>
                  <p>Con su habilidad para desarrollar mercados culturalmente diversos en distintas industrias, es la persona ideal para llevar nuestros productos a cada latino que quiera comenzar su viaje financiero.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={hector} />
                  </div>
                </div>
              </div>
              <div class="text-container-2">
                <h3>Hector Alexis Davila</h3>
                <h6>Operaciones</h6>
                <div>
                  <p>Con una combinación dinámica de excelencia operativa y experiencia financiera, él es fundamental para mejorar la eficiencia, la productividad y la rentabilidad.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterEn />
    </Container>
  )
}

export default TeamEs;