import React from "react";
import "../../styles/style.css";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/navbar/english";
import Footer from "../../components/footer/english";
import BlogCarousel from "../../components/blog_carousel/english";
import left_banner from "../../assets/images/left_banner.png";
import right_banner from "../../assets/images/right_banner.png";
// import logo1 from "../../assets/images/logo1.webp";
// import play_store from "../../assets/images/google_play_button.png";
// import app_store from "../../assets/images/app_store_button.png";
import loan_heading from "../../assets/images/personal_loans_heading.png";
import MyComponent from "../../assets/images/marqee_partners.png";
import Marquee from "react-fast-marquee";
import img1 from "../../assets/images/news_marquee_1.jpg";
import img2 from "../../assets/images/news_marquee_2.webp";

const HomeEn = () => {
  return (
    <div className="home_main">
        <Navbar langToggleHref="/es"/>
        <Container style={{paddingTop:0}}>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} className="left_banner_box">
              <img alt="nuestro is here to help!" src={left_banner} className="left_banner"/>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className="right_banner_box">
              <img alt="happy nuestro user" src={right_banner} className="right_banner"/>
            </Col>
          </Row>
        </Container> 

<div style={{backgroundColor:'#ffffff'}}>
        <Container fluid className="white_container">
          <Row>
            <h1 className="hh1">Nuestro was created to help our comunidades<br/> take control of our<span style={{color:'rgb(33, 67, 136)', fontFamily:'AvenirBold'}}> financial future.</span></h1>
          </Row>
        </Container>

        {/* <Container fluid className="white_container_2">
          <Row>
            <div className="download" style={{ display: 'flex', alignItems: 'center'}}>
              <div><img alt="nuestro logo" src={logo1}/></div>
              <div style={{paddingLeft:'1rem'}}><h2 style={{fontFamily:'AvenirBold', color:'rgb(33, 67, 136)', lineHeight:'1.5rem',}}>DOWNLOAD</h2>
              <h2 style={{fontFamily:'AvenirBold', color:'rgb(232, 0, 120)', lineHeight:'1.5rem',}}> THE APP NOW</h2></div>
            </div>
          </Row>
        </Container> */}

        <Container fluid className="white_container_3">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
            {/* <div style={{padding:'2rem 2rem', position:'relative'}}>
              <div><img alt="play store" src={play_store} width="250rem"/></div>
              <br/>
              <div><img alt="app store" src={app_store} width="250rem"/></div>
            </div> */}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <img alt="personal loans" src={loan_heading} className="personal_loans_img"/>
              <div className = "personal_loan">
                <p>We are part of the comunidad and are committed to providing you with lending products that are easy to use and considerate of our cultural needs.</p>
              </div>
              <div className="personal_loan_button">
                <a href="https://www.nuestro.loans/" target="_blank" rel="noopener noreferrer" className="apply-button">Apply Now</a>
              </div>
            </Col>
          </Row>
        </Container>
        </div>
        <Container className = "blog_news">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}b style={{textAlign:'center'}}>
              <BlogCarousel/>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
            <marquee direction="up" height="380px" style={{textAlign:'left', border:'2px solid #ffffff', padding:'10px', color:'#ffffff !important', borderRadius:'30px'}}>
    <div width="100px">
        <div style={{color:'#ffffff'}}>
            <p style={{color:'rgb(232, 0, 120)'}}>4/18/2023 08:52 PM</p>
            <h1>2022 40 Under 40 Awards</h1>
            <img src={img1}/>
            <p>Mixing both natives and newcomers, our 40 Under 40 Awards recognize the faces that will shape Nashville in the years to come by highlighting high-performing executives under the age of 40. For thirteen years now, we’ve recognized Music City’s best and brightest business and community leaders who are making their mark — and promising to do even more — before the age of 40.
 
 Our 40 winners will be highlighted in a special publication and honored at an awards program.  Who do you know that fits this description?  Nominate them now!</p>
 <button style={{color:'#ffffff', padding:'10px', backgroundColor:'blue', border:'2px solid transparent'}}><a href="https://www.bizjournals.com/nashville/nomination/86027/2022/2022-40-under-40-awards#/" target="_blank" rel="noopener noreferrer" style={{color:'#ffffff'}} >Read More</a></button>
        </div>
    </div>
<br/>
    <div width="100px">
        <div style={{color:'#ffffff'}}>
            <p style={{color:'rgb(232, 0, 120)'}}>4/18/2023 08:56 PM</p>
            <h1>Biden moves to expand DACA recipients' access to government-funded health insurance</h1>
            <img src={img2}/>
            <p>The Biden administration has announced a plan to expand access to Affordable Care Act and Medicaid coverage for DACA recipients. The change would treat DACA recipients more like other groups with temporary legal status.

The Department of Health and Human Services will propose an amended definition of "lawful presence" to include recipients of the Deferred Action for Childhood Arrivals program, the White House said on Thursday.

"We recognize that every day counts, and we expect to get this done by the end of the month," the White House said in a statement. The proposed rule would allow DACA recipients to apply for coverage in the health insurance marketplace and through their state Medicaid agency.</p>
        </div>
    </div>
  </marquee>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col><Marquee>

<img src={MyComponent}/>
  </Marquee></Col>
          </Row>
        </Container>
  <Footer/>
    </div>
  );
};

export default HomeEn;
