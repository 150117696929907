import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";


const Blog18Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/3-tips-to-take-care-of-nuestro-dinero"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Apr 20, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>3 Consejos Para Cuidar Nuestro Dinero</h1>
                    <br/>
                    <p>
                    El dinero es algo que todos conocemos y de lo que hablamos porque es muy importante en nuestras vidas. Y aunque todos sabemos que necesitamos dinero para vivir, a la mayoría de nosotros nunca se nos enseñó cómo administrarlo de manera efectiva.
                    <br/>
                    <br/>

ISi desea administrar mejor sus finanzas, aquí hay 3 consejos que puede implementar:

<br/>
<br/>
<b>Organiza su dinero</b>
<br/>
A veces gastamos de más, nos sentimos estresados ​​por nuestro dinero y/o no alcanzamos nuestras metas financieras porque tenemos las finanzas desorganizadas. Puede usar un presupuesto para comenzar a organizar sus finanzas.
<br/>
                    <br/>
<b>Toma conciencia de sus hábitos de dinero</b>
<br/>
Debemos esforzarnos por desarrollar hábitos de dinero que nos sirvan para que podamos mejorar nuestra calidad de vida, alcanzar nuestras metas financieras y generar riqueza. Puede comenzar a identificar sus hábitos de dinero haciendo un seguimiento de sus gastos.
<br/>
                    <br/>

<b>Sea intencional con su dinero</b>
<br/>
Un hábito de dinero realmente importante para desarrollar es volverse intencional con su dinero para que pueda gastar solo en cosas que necesita para vivir, que valora y que le brindan alegría.

<br/>
                    <br/>
WEsperamos que con estos tres consejos de dinero, pueda tomar el control de sus finanzas y alcanzar sus metas financieras porque…
<br/>
                    <br/>

                    ¡sí se puede! 
<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas</b>
                    <b>dinero </b>
                    <b>debito</b>
                    <b>deuda</b>
                    <b>comunidad</b>
                    <b>Credito</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog18Es;