import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/dp.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog3Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/nuestro-features-part-1-luis-pedraza-resource-coordinator-at-tirrc"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Jane Flautt &nbsp;&bull;&nbsp; 
Sep 21, 2023
 &nbsp;&bull;&nbsp; 3 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Nuestro Reseña, 1ra Parte: Luís Pedraza, Coordinador de Recursos de TIRRC</h1>
                    <br/>
                    <p>
                    Luis Pedraza no esperaba terminar donde está ahora. Desde temprana edad, su sueño era convertirse en abogadode inmigración, representando casos y marcandouna diferencia a nivel individual. No fue hasta que le pidió a su mentora una carta de recomendación para la facultad de derecho y ella le dijo que no creía que la facultad de derecho fuera el camino para él , en ese momento algo le hizo click: tal vez estaba destinado a una carrera diferente. El trabajo voluntario de Luis con organizaciones sin fines de lucro mientras estaba en la Universidad de Tennessee, Knoxville, junto con su pasión por la reforma política, lo llevaron a la Coalición por los Derechos de Inmigrantes y Refugiados de Tennessee, donde ahora es Coordinador de Recursos. Específicamente, Luis realiza trabajo de educación y acceso a COVID-19, donde puede aprovechar su experiencia previa como proveedor de contactos para brindar apoyo y recursos integrales a las personas que acuden a TIRRC.
                    <br/>
<br/>
Una de las razones principales por las que comenzó a inclinarse hacia las organizaciones sin fines de lucro es que lugares como TIRRC están analizando un panorama más amplio de por qué la gente acude a ellos en primer lugar. Los servicios son sólo una parte de lo que hace TIRRC, explicó Luis. El objetivo más amplio es reformar los sistemas y sus fallas para que algún día nadie necesite estos servicios, y a Luis también le apasiona el objetivo de cambio sistémico de TIRRC a nivel personal, ya que pasó la mayor parte de su vida en Tennessee. Ser latino en el Sur es “una experiencia única”, explicó Luis, y a veces ha sido frustrante, especialmente porque las grandes organizaciones nacionales sin fines de lucro a menudo pasanpor alto a Tennessee y el Sur por no ser “lugaresde mente abierta”,lo que sólo perjudica a personas como los inmigrantes, comunidades que ya están desatendidas por los creadores de políticas locales y estatales.
<br/>
<br/>
Luis se describe a sí mismo como una primera generación nacida en los EE. UU. y una segunda generación latina que vive en los EE. UU., ya que sus padres eran originalmente de México y él nació en California antes de mudarse a Tennessee cuando era muy joven. Al crecer, recuerdauna infancia agradable, pero a medida que creció,se volvió más consciente de los desafíos que enfrentaba su familia como de estatus mixto: algunos miembros de su familia son indocumentados y en un momento su familia incluso tuvo que lidiar con un incidente de deportación. Al ver que estas dificultades lo afectaban a él y a su familia, Luis quería ser una fuente de apoyo para su familia y su comunidad en general, lo que él ve como una mentalidad común entre los latinos de segunda generación.
<br/>
<br/>
“Me veo como un vínculo ascendente” para quienes lo rodean, dijo Luis, al igual que sus padres y las generaciones mayores que no tenían el mismo acceso a recursos y oportunidades que él. Luis se convirtió en la primera persona de su familia en terminar la universidad y pudo conseguir un trabajo que valora en un lugarque ama, cosas que sus padres no pudieron lograr. Como inmigrante latino de segunda generación, Luis explicó cómo él y muchas otras personas como él se sienten presionados a cuidar constantemente del resto de su familia y a planificar el futuro (en particular, el futuro financiero) de sus padres y familias. Crecer con ese tipo de responsabilidad sobre tus hombros puede ser abrumador, por lo que un consejo que Luis tiene para las personas que podrían estar luchando contra la ansiedad por sí mismos y sus familias es que se perdonena sí mismos y a sus límites:el agotamiento es real y hay que hacerlo. Cuídate a ti mismo antes de cuidar a los demás, dijo Luis.

<br/>
<br/>
Luis aconsejó específicamente a personas como él, que podrían haber crecido en una familia que no hablaba en absoluto de finanzas ni de dinero, que se inclinaran por la educación y el crecimiento financieros. Solía sentirse avergonzado como un adulto que no tenía mucha educación financiera o confianza hastaque comenzó a hacer más preguntas y buscar recursos para educarse. Es posibleque la primera persona a la que le preguntes no pueda responder tu pregunta, explicó Luis, pero probablemente conozca a alguien que pueda ayudarte; sin embargo, debes iniciar esa conversación y sentirte incómodo antes de que pueda ocurrir cualquier cambio. Ahora, a los 26 años, Luis se siente menos intimidado por los mecanismos detrás del mundo financiero y quiere que la gente sepa que, sí, al principio te sentirás intimidado por las inversiones, los planes 401(k) y los puntajes crediticios, pero una vez que empieces a preguntar, preguntas y aprendiendo más, ganarás confianza en ti mismo tal como lo hizo él.


</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog3Es;