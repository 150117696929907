import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/dp.png";
import b3 from "../../../assets/images/rb3.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog2Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };
    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/nuestro-features-part-2-elizabeth-narváez-vega-college-success-coach-at-conexión-américas"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Jane Flautt &nbsp;&bull;&nbsp; 
Oct 30, 2023
 &nbsp;&bull;&nbsp; 3 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión Américas</h1>
                    <br/>
                    <p>
                        <b>Elizabeth Narváez-Vega está ayudando a estudiantes universitarios de primera generación en el camino hacia el éxito en Conexión Américas: así es como llegó allí y los consejos que les da a otros estudiantes de primera generación.</b>
                    <br/>
<br/>
Elizabeth Narváez-Vega sabía que quería ser maestra desde que era joven; siempre pretendía ser maestra durante el tiempo de juego con sus hermanos cuando eran niños. Y aunque su trabajo actual no es exactamente el mismo que solía imaginar, el día a día de Liz se dedica a ayudar a los jóvenes a obtener la experiencia educativa que merecen. Como asesora de éxito universitario en<a href="https://www.conexionamericas.org/" target="_blank" rel="noopener noreferrer"> Conexión Américas</a>, Liz trabaja con todos los estudiantes que pasan por los programas de escuela secundaria de Conexión y continúa apoyándolos mientras están en la universidad, lo que implica todo, desde explicarles cómo presentar una FAFSA hasta mostrarles a los estudiantes qué recursos están disponibles para ellos en sus campus. Todos los estudiantes de Liz son estudiantes universitarios de primera generación y, como graduada de primera generación, ella aprovecha su propia experiencia como hija de inmigrantes para ayudar a los estudiantes que vienen a Conexión en busca de apoyo.
<br/>
<br/>
Los padres de Liz emigraron a los Estados Unidos hace casi 30 años y Liz creció en Nashville, aunque con frecuencia regresaba a México varias veces al año ya que la mayor parte de su familia estaba allí. Estos viajes, junto con la influencia de sus padres, la alentaron a permanecer profundamente arraigada a su herencia mexicana, compartió Liz. Cuando era niña, a principios de la década de 2000, la comunidad de inmigrantes latinos era mucho más pequeña en el centro de Tennessee y Nashville era mucho menos acogedor, explicó. No había tanta comprensión de la experiencia de los inmigrantes ni siquiera de lo que significaba “primera generación”. Liz tuvo “una relación complicada [con su identidad latina] al crecer en el sur cuando no había mucha representación de la latinidad”, dijo. Liz, también recordó ciertos mensajes en la escuela que impulsaban que las clases fueran solo en inglés, y por eso pensaba que hablar español en la escuela y fuera de casa era “vergonzoso”. Pero sus padres se opusieron a esto: la alentaron a abrazar su bilingüismo y verlo como una ventaja en lugar de algo de lo que avergonzarse.
<br/>
<br/>
A medida que Liz avanzaba en la escuela secundaria, se involucró en la programación de Conexión, y fue esta experiencia la que la inspiró a buscar puestos vacantes en Conexión después de graduarse de la Universidad de Belmont en 2019. Después de dedicarse a estudiar no solo educación sino también trabajo social en Belmont, Liz se sintió especialmente atraída por Conexión ya que trabajaría con los mismos estudiantes a lo largo de los años, en lugar de solo un ciclo anual si hubiera seguido la enseñanza en la escuela. Ahora, mientras Liz continúa trabajando con estudiantes en las Escuelas Públicas Metropolitanas de Nashville y en las universidades, ha visto cambios positivos dentro de estos sistemas no sólo para ser más inclusivos con los estudiantes bilingües y los hablantes no nativos de inglés, sino también para aumentar la diversidad tanto dentro del estudiantado y el cuerpo docente. Liz también ha notado que están apareciendo más recursos en los campus universitarios creados específicamente para estudiantes de primera generación. Cuando estaba en Belmont, explicó que los recursos de primera generación a menudo se agrupaban con los recursos de estudiantes internacionales, lo cual era un problema ya que estos dos grupos de estudiantes a veces requieren diferentes tipos de apoyo.
<br/>
<br/>
Para sus estudiantes actuales, además de las preguntas académicas, Liz enfatizó que algunas de las preguntas más comunes que le hacen son sobre préstamos y creación de crédito. A menudo dirige a los estudiantes a otras organizaciones que están específicamente orientadas al asesoramiento financiero, y les explica que "una de las mayores barreras y obstáculos para los estudiantes de primera generación es la educación financiera... [como] comprender el tipo de préstamos que están solicitando". Cuando se le preguntó qué consejo le daría a los estudiantes o recién graduados, destacó la importancia del crédito. “El crédito es la base de muchos de los movimientos financieros que desea realizar”, dijo, y Liz lamenta no haber comenzado a generar crédito mientras aún estaba en la universidad. Al provenir de un hogar que, como muchos hogares de inmigrantes de primera generación, enfatizaba el ahorro, a Liz le tomó algún tiempo sentirse cómoda con el endeudamiento para eventualmente construir crédito y estabilidad financiera. Otro activo clave que Liz recomienda es que los jóvenes aprovechen su red; su red de conexiones es la forma en que Liz encontró su trabajo en Conexión Américas que le encanta. 
<br/>
<br/>
De manera más general, Liz también expresó su apoyo a los estudiantes universitarios, particularmente a los de primera generación, quienes pueden sentirse abrumados al ingresar a la universidad y tratar de determinar sus objetivos profesionales. A los estudiantes de primera generación que experimentan dudas sobre sí mismos, con el síndrome del impostor o ansiedad profesional, Liz quería recordarles que, en primer lugar, no estás atado al primer trabajo que obtienes cuando sales de la escuela, y segundo, que “has hecho el trabajo para estar allí [y] mereces” tu educación y cualquier carrera profesional que elijas seguir.
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b3}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña, 1ra Parte: Luís Pedraza, Coordinador de Recursos de TIRRC

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog2Es;