import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog8En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/take-control-of-your-debt"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jul 6, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>

                    <h1>Take Control of Your Debt</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    It’s important to learn how to take control of your debt not only so you can build assets but also so you can have more financial peace of mind.

                    <br/>
                    <br/>
The first step you need to take is to write down how much you owe. Doing this may feel scary, overwhelming, and/or uncomfortable, but it is a necessary step. After you know how much you owe, you can develop a plan to tackle it. 
<br/>
                    <br/>

In that plan, ensure that you use a debt repayment strategy, celebrate your wins, and actively remember that you are in control of your debt. Here are two effective debt repayment strategies you can use:

<br/>
                    <br/>
<p style={{textDecoration:'underline'}}>Snowball Method </p>

This method prioritizes paying off the smallest loan first, then focusing on the next smallest loan. Repeat until debt free. 
<ul>
<li>It is best for people who need instant gratification </li>
<li>It also helps build momentum on the loan payoff journey</li>
</ul>
<br/>
                    <br/>
<p style={{textDecoration:'underline'}}>Avalanche Method </p>

This method prioritizes paying off the loan with the highest interest rate first, then focusing on the loan with the 2nd highest interest rate. Repeat until debt free. 
<ul>
<li>This method works best for people who are more analytical and don't need instant gratification</li>
<li>It can save money on interest</li>
</ul>
<br/>
You should choose the method that works best for YOU because doing so will help you tackle your debt the best you can. So here’s to taking control of our debt because we can and we WILL!
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog8En;