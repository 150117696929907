import React from "react";
import "../../styles/style.css";
import "../../styles/slidein.css";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/navbar/english";
import Footer from "../../components/footer/english";
import left_banner from "../../assets/images/why_nuestro.png";
import video1 from "../../assets/videos/Nuestro_Info_Website.mp4";
import happy_boy from "../../assets/images/about_happy_boy.png";
import about1 from "../../assets/images/About1-8.webp";
import about2 from "../../assets/images/About2-8.webp";
import about3 from "../../assets/images/About3-8.webp";
import about4 from "../../assets/images/aboutnuestro3-8.webp";
import behind_laugh from "../../assets/images/behind_laugh.jpg";

const AboutEn = () => {
  return (
    <div style={{ overflowX: "hidden", backgroundColor: "rgb(33, 67, 136)" }}>
      <Navbar langToggleHref="/es/about" />
      <Container style={{ paddingTop: 0 }}>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} className="left_about_box">
            <img alt="why nuestro" src={left_banner} className="left_about" />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className="right_about_box">
            <video
              loop="true"
              autoplay="true"
              muted
              className="right_about slide-in-right"
            >
              <source src={video1} type="video/mp4" />
            </video>
          </Col>
        </Row>
      </Container>
      <Container style={{ paddingTop: 0 }}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="right_boy_box order-md-2 order-lg-2"
          >
            <p className="right_boy">
              Nuestro was created to help our
              <br />
              <span
                style={{
                  color: "rgb(232, 0, 120)",
                  fontFamily: "RockoUltraFLF",
                }}
              >
                {" "}
                Comunidad
              </span>{" "}
              take control over
              <br /> their financial future.
            </p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="left_boy_box order-md-1 order-lg-1"
          >
            <img alt="happy user" src={happy_boy} className="left_boy" />
          </Col>
        </Row>
      </Container>
      <div className="white_about_container">
        <Container fluid>
          <Row style={{ paddingTop: "25px" }}>
            <Col sm={12} md={4} className="p-2">
              <img alt="save more" src={about1} width="200px" />
            </Col>
            <Col sm={12} md={4} className="p-2">
              <img alt="better financial habits" src={about2} width="200px" />
            </Col>
            <Col sm={12} md={4} className="p-2">
              <img alt="manage money smartly" src={about3} width="200px" />
            </Col>
          </Row>
          <Row>
            <p className="about_text">
              We believe in the power of<span> identity</span> and that our
              <span> financial liberation</span> can be
              <br /> achieved with something that is ours, something that is{" "}
              <span>Nuestro.</span>
            </p>
          </Row>
          <Row style={{ paddingBottom: "50px", textAlign: "center" }}>
            <center>
              <img alt="Nuestro unique" src={about4} className="unique" />
            </center>
          </Row>
          <Row style={{ paddingBottom: "50px", textAlign: "center" }}>
            <center>
              <h3 className="meet-our-team-heading">Get to know the faces that brings a unique set of skills, experiences, and perspectives to the table, uniting under a shared commitment to excellence, learn about their backgrounds, and discover what inspires them in their roles.</h3>
              <br/>
              <a href="/en/team" className='pt-2 pb-2 ps-4 pe-4 meet-our-team'>Meet Our Team</a>
            </center>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className="left_behind_box">
            <h1>
              Who is
              <br />
              behind
              <br />
              <span>Nuestro?</span>
            </h1>
            <Col xs={12} sm={12} md={7} lg={7} className="left_help_box">
              <p>
                <span>Nuestro</span> is our way, to help our people, on our
                terms. We know that closing the financial literacy gap is within
                reach and it will bring incredible benefits to our
                <span> comunidad.</span>{" "}
              </p>
            </Col>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="right_behind_box">
            <img alt="Nuestro happy users " src={behind_laugh} />
            <Col xs={12} sm={12} md={7} lg={7} className="right_help_box">
              <p>
                We are fully committed to developing creative tools for you to
                thrive and watch your money grow. This is how we contribute to
                seeing <span>your dreams come true.</span>{" "}
              </p>
            </Col>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default AboutEn;
