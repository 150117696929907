import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog9Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/how-can-i-use-debt-to-build-assets"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jun 22, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
               
                    <h1>¿Cómo puedo usar la deuda para crear activos?</h1>
                    <br/>
                    <p>
                    Si se ha hecho esta pregunta ¡está en el camino correcto! Incluso si no tiene esa pregunta, nos complace que esté aquí aprendiendo más sobre deudas y activos :)
                    <br/>
                    <br/>

Primero definamos qué es un activo. Los activos son algo que pueden generar dinero para usted en el futuro. Ejemplos de activos incluyen educación superior, propiedad de una casa, acciones y/o tener un negocio.
<br/>
                    <br/>

Aunque a muchos de nosotros, por una variedad de razones, no nos gusta tener deudas, a veces es necesario que acumulemos algunas deudas para aprovecharlas y construir activos. Por ejemplo, la mayoría de nosotros necesitamos obtener una hipoteca para comprar una casa u obtener un préstamo comercial para comenzar y construir un negocio. Otro ejemplo de aprovechar la deuda para generar activos es obtener préstamos estudiantiles para continuar con la educación post-secundaria.
<br/>
                    <br/>

Como muestran estos ejemplos, la deuda a menudo es necesaria para construir activos. Sin embargo, eso no significa que dejará que su deuda lo ahogue financieramente. Es importante que aprenda a tomar el control de su deuda para que pueda aprovecharla adecuadamente para su beneficio financiero.
<br/>
                    <br/>

La próxima publicación del blog explicará cómo puede tomar el control de su deuda para que funcione para usted ¡así que esté atento!
<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>debito </b>
                    <b>deuda </b>
                    <b>comunidad </b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog9Es;