import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog17Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };


    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/el-poder-del-budget"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Apr 27, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>El Poder del Presupuesto</h1>
                    <br/>
                    <p>
                    ¡Hola! 

                    <br/>
                    <br/>
                    Es muy importante que tenga un presupuesto porque tener uno es fundamental para sus finanzas. 

<br/>
                    <br/>
<b>¿Qué es un presupuesto?</b>
<br/>
Un presupuesto es simplemente una herramienta para ayudarle a organizar su dinero 🤯 Está bajo su control y puede hacerlo lo más restrictivo o flexible posible. ¡No tiene por qué privarle! 🥳  
<br/>
<br/>
<b>¿Por qué es necesario tener un presupuesto?</b> 
<br/>
Un presupuesto lo ayuda a ser más intencional con sus gastos para que pueda gastar en cosas y experiencias que le brinden alegría mientras logra sus objetivos financieros 🥳
<br/>
                    <br/>

<b>¿Cómo puedo iniciar un presupuesto?</b> 
<br/>
Le recomendamos que comience con un presupuesto simple. Puede probar el presupuesto 50/30/20. Así es como se desglosa este presupuesto:
<br/>
                    <br/>

                    El 50% de su dinero debe ir a las necesidades.
<br/>
30% debe ir a deseos
<br/>
20% debe ir a ahorros
<br/>
                    <br/>

                    ¡Hoy es el día en que desarrollamos un presupuesto porque merecemos alcanzar todos nuestros objetivos financieros!
<br/>
                    <br/>

                    ¡Pa'lante!


<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>dinero </b>
                    <b>debito</b>
                    <b>comunidad</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog17Es;