import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import b1 from "../../assets/images/b1.webp";
import b2 from "../../assets/images/b2.webp";
import b3 from "../../assets/images/b3.webp";

function blog_carousel_es() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Carousel indicators={false}>
        <Carousel.Item>
          <a href="/es/blog/nuestro-features-part-3-enrico-lopez-yañez-principal-pops-director-at-the-nashville-symphony">
            <Card
              style={{
                width: "18rem",
                backgroundColor: "transparent",
                color: "#ffffff",
              }}
            >
              <Card.Img variant="top" src={b1} />
              <Card.Body style={{ textAlign: "left" }}>
                <Card.Text>Dec 8, 2023</Card.Text>
                <Card.Title className="carousel_title">
                  Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director
                  Principal de Pops en la....
                </Card.Title>
              </Card.Body>
            </Card>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/es/blog/nuestro-features-part-2-elizabeth-narváez-vega-college-success-coach-at-conexión-américas">
            <Card
              style={{
                width: "18rem",
                backgroundColor: "transparent",
                color: "#ffffff",
              }}
            >
              <Card.Img variant="top" src={b2} />
              <Card.Body style={{ textAlign: "left" }}>
                <Card.Text>Oct 30, 2023</Card.Text>
                <Card.Title className="carousel_title">
                  Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito
                  Universitario en...
                </Card.Title>
              </Card.Body>
            </Card>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/es/blog/nuestro-features-part-1-luis-pedraza-resource-coordinator-at-tirrc">
            <Card
              style={{
                width: "18rem",
                backgroundColor: "transparent",
                color: "#ffffff",
              }}
            >
              <Card.Img variant="top" src={b3} />
              <Card.Body style={{ textAlign: "left" }}>
                <Card.Text>Sep 21, 2023</Card.Text>
                <Card.Title className="carousel_title">
                  Nuestro Reseña, 1ra Parte: Luís Pedraza, Coordinador de
                  Recursos de TIRRC...
                </Card.Title>
              </Card.Body>
            </Card>
          </a>
        </Carousel.Item>
      </Carousel>
      <style>{`
        .carousel-control-prev {
          left: -50px; /* Adjust the left position of the previous arrow */
        }
        .carousel-control-next {
          right: -50px; /* Adjust the right position of the next arrow */
        }
                .carousel_title {
                  color: #ffffff;
                  font-family: AvenirBold;
                  font-weight: bold;
                }
                .carousel_title:hover {
                  color: rgb(232, 0, 120);
                  font-family: AvenirBold;
                }
        
                @media (max-width: 991px) {
                  .carousel-control-prev {
                    left: -37px; /* Adjust the left position of the previous arrow */
                  }
                  .carousel-control-next {
                    right: -37px; /* Adjust the right position of the next arrow */
                  }
                }
      `}</style>
    </div>
  );
}

export default blog_carousel_es;
