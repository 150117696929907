import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/dp.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog4Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/meet-israel-tovar-nuestro-s-director-of-financial-literacy"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Jane Flautt &nbsp;&bull;&nbsp; 
Aug 28, 2023
 &nbsp;&bull;&nbsp; 3 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                    <h1>Conoce a Israel Tovar, Director de Educación Financiera de Nuestro</h1>
                    <br/>
                    <i>Israel Tovar está listo para ayudar a su comunidad a tomar control de sus finanzas y desarrollar relaciones sanas y seguras con el dinero.  </i>
                    <br/>
                    <br/>
                    <p>
                    Nuestro se complace en presentar a Israel Tovar como Director de Educación Financiera y un miembro vital de nuestro equipo. Israel proviene de un entorno arraigado en la educación, el desarrollo comunitario y el empoderamiento financiero, y tiene fuertes vínculos con la comunidad de Nashville que ha crecido y trabajado aquí.

                    <br/>
                    <br/>
Antes de regresar a Nashville para trabajar en Nuestro, Israel recibió su maestría en educación de la Universidad de Stanford y trabajó como profesor en California, Tennessee, y Washington, D.C. Israel también ha buscado oportunidades empresariales, y más recientemente en el 2021 junto a su hermana cofundó The Dream Teacher Project una organización que entrena a los maestros de todo Estados Unidos sobre cómo tomar el control de sus finanzas y construir riqueza. La experiencia de Israel en educación es una de las razones por las que se sintió tan atraído por Nuestro como empresa, y como Director de Educación Financiera, se dedica a garantizar que los productos y servicios que Nuestro proporciona estén lo más basados posible en la comunidad. Y este objetivo es más que profesional: Israel se basa en su propia experiencia personal al crecer y vivir en comunidades latinas en Nashville y más allá.
<br/>
                    <br/>

Israel nació en Tijuana, México, y su familia se mudó a Los Ángeles, California, cuando él era muy joven. Solo unos años más tarde, en 2007, su familia se mudó de nuevo, esta vez a Nashville, Tennessee. El cambio del sur de California a un Estsdo del Sur fue a veces discordante: Israel recordó que en el momento de la mudanza de su familia había menos comunidades latinas en Nashville, especialmente en comparación con el lugar donde creció en Los Ángeles y Tijuana. Para cuando estaba en la escuela secundaria y pensando en su futuro, estaba listo para dejar el Sur, especialmente cuando también llegó a un acuerdo con su identidad queer, explicó Israel. El siguiente paso: Universidad de Yale.
<br/>
                    <br/>

Después de graduarse de Yale como estudiante universitario de primera generación, Israel recordó haberse sentido poco preparado para el mundo financiero en el que estaba ingresando: no tenía una riqueza generacional a la que recurrir ni conocimientos financieros sustanciales, a pesar del prestigio de ser un graduado de Yale. Israel explicó cómo la “mentalidad de escasez” de su familia influyó en su propia relación personal con el dinero durante gran parte de su joven vida. Eventualmente, pudo volver a imaginar esta mentalidad de escasez y transformarla en una "mentalidad de abundancia", como él la llamó, y pudo ver el dinero "como una herramienta más que como algo para acumular". En última instancia, Israel pudo alcanzar metas financieros a una edad más temprana de lo que jamás imaginó, y con el salario de un maestro.
<br/>
                    <br/>

Sin embargo, Israel reconoce cuán único es su viaje con las finanzas, especialmente en lo que respecta al desarrollo de una mentalidad financiera que difiere significativamente de la de sus padres. Muchas personas latinas de segunda generación, que, como Israel, pueden haberse graduado como estudiantes universitarios de primera generación con muy poca educación financiera, aún tienen la misma relación con el dinero y la riqueza que la generación de sus padres, a pesar de ganar más dinero a medida que avanzan en sus carreras. Es dentro de esta brecha en educación y confianza financiera que Nuestro puede intervenir, explicó Israel. 
<br/>
                    <br/>

Como ex maestro, Israel comprende el poder de la educación. Sin embargo, explicó que la educación es una pieza importante del rompecabezas, pero no cambia automáticamente la mentalidad de escasez, el miedo a invertir o el no hacer presupuestos, especialmente cuando esa persona puede tener experiencias pasadas complicadas o angustiosas con el dinero y la seguridad financiera. Israel también reconoce que es más difícil que nunca encontrar un trabajo y lograr seguridad financiera en el entorno económico actual debido a la inflación, los despidos y un mercado laboral difícil, por lo que insta a la gente a perdonarse a sí mismos y reconocer que las decisiones individuales y la educación pueden solo cambiar hasta cierto punto. Pero el primer paso es la educación, aclaró Israel, y esta educación debe tener en cuenta la cultura e incluir las experiencias vividas por las personas que reciben educación.  
<br/>
                    <br/>

Mirando hacia el futuro de Nuestro y su primer año en el terreno financiero, Israel espera que Nuestro ayude a las personas en las comunidades de todo el sureste a comenzar a tomar control de sus finanzas y a desarrollar relaciones sanas y seguras con su dinero. Mientras se adentra en su puesto como Director de Educación Financiera en Nuestro, Israel dijo: "Estoy muy emocionado de ser parte de esta empresa y verla florecer porque sé que va a ayudar a la comunidad.”


</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión Américas...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog4Es;