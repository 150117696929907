import { useState } from 'react';
import { ShareSocial } from 'react-share-social';

export default function RSSUsage({ onClose, buttonLink }) { // Receive onClose and buttonLink props
  const [isShareOpen, setIsShareOpen] = useState(true); // Set initial state to true to open the share screen by default

  const handleClose = () => {
    setIsShareOpen(false); // Close the share screen
    onClose(); // Trigger the onClose function passed from the parent component
  };

  const style = {
    root: {
      borderRadius: 3,
      border: 0,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
    },
    copyContainer: {
      border: '1px solid blue',
      background: 'rgb(0,0,0,0.7)',
    },
    title: {
      color: 'aquamarine',
      fontStyle: 'italic',
    }
  };

  const currentURL = window.location.href;

  return (
    <div style={{ backgroundColor: 'black', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999 }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          {isShareOpen && (
            <ShareSocial  
              url={currentURL + buttonLink} // Concatenate buttonLink with the URL
              socialTypes={['facebook', 'twitter', 'linkedin']}
              onSocialButtonClicked={data => console.log(data)}
              style={style}
            />
          )}
        </div>
        <div style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: '#ffffff' }}>
          {/* Use onClick event handler directly on the 'X' to trigger handleClose */}
          <a onClick={handleClose}>X</a>
        </div>
      </div>
    </div>
  );
}
