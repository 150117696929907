// Navbar.jsx

import { useRef, useState } from "react";
import { FaTimes, FaSearch } from "react-icons/fa";
import { Container } from 'react-bootstrap';
import { RiMenu4Fill } from "react-icons/ri";
import "../../styles/main.css";
import loans_logo from "../../assets/images/Nuestro_info_logo.webp";
import nuestro_icon from "../../assets/icons/nuestro_icon.png";
import es_flag from "../../assets/images/spanish_flag.png";

function Navbar({ langToggleHref }) {
  const navRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <header style={{zIndex:100, position:'relative', marginLeft:'8%'}}>
      <Container className="nav_container">
      <img src={loans_logo} alt="nuestro loans logo" className="main_mobile_logo"/>
        <nav ref={navRef} style={{textAlign:'center'}}>
          <a href="/"><img src={loans_logo} alt="nuestro loans logo" className="logo_img"/></a>
          <a href="/"><img src={nuestro_icon} alt="nuestro loans logo" width="80rem" className="mobile_logo" /></a>
          <a href="/">Home</a>
          <a href="/en/products">Products</a>
          <a href="/en/about">About</a>
          <a href="/en/financial-literacy">Financial Literacy</a>
          <a href="/en/team">Team</a>
          <a href="/en/community-fund" className="mobile_options">Community Fund</a>
                <a href="/en/blog" className="mobile_options">Blog</a>
                <a href="/en/contact" className="mobile_options">Contact Us</a>
                <a href="/en/disclosures" className="mobile_options">Disclosures</a>
          <div className="more-link" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
            <a href="#" className="pc_options">More</a>
            {showDropdown && (
              <div className="dropdown-menu">
                <a href="/en/community-fund">Community Fund</a>
                <a href="/en/blog">Blog</a>
                <a href="/en/contact">Contact Us</a>
                <a href="/en/disclosures">Disclosures</a>
              </div>
            )}
          </div>
          <div className="language-toggle">
          <a href={langToggleHref}><img src={es_flag} alt="English flag" /></a>
          </div>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <RiMenu4Fill />
        </button>
      </Container>
    </header>
  );
}

export default Navbar;