import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/Diego_Eguiarte.jpg";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog21Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{ backgroundColor: 'rgb(33, 67, 136)' }}>
            <Navbar langToggleHref="/blog/bienvenidos-a-nuestro-the-moment-is-ours"/>
            <Container className="resp_blog">
                <Row>
                    <Col>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                            <p style={{ fontSize: '13px' }}><img src={dp} width="40px" style={{borderRadius:'20px'}}/> &nbsp;Diego Eguiarte
 &nbsp;&bull;&nbsp;
                                Oct 21, 2022
                                &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                        </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                        
                        <h1>El momento es Nuestro.</h1>
                        <br />
                        <p>
                        Comencé Nuestro para cerrar la brecha de educación financiera, que evita que nuestras comunidades aprovechen al máximo los productos y servicios financieros existentes ofrecidos por organizaciones convencionales.
                        <br />
                            <br />

Nuestro se enfoca en desarrollar mejores hábitos financieros con nuestros usuarios. Brindando una cartera de productos de nivel inicial que preparará a nuestra comunidad de usuarios para interactuar con éxito con productos más complejos en su trayectoria financiera.
<br />
                            <br />

Sabemos que mejores hábitos financieros, combinados con una ética laboral excelente y talento, es un camino claro para crear riqueza generacional para nuestras familias.
<br />
                            <br />

Nuestras comunidades han demostrado grandes capacidades para iniciar y hacer crecer negocios, generar empleo y aumentar el flujo de efectivo en general.
<br />
                            <br />

Pero eso no es suficiente, aún tenemos que aprender cómo administrar mejor nuestro dinero, invertir y aprovechar los múltiples productos financieros disponibles que convierten nuestro dinero en riqueza.
<br />
                            <br />

Nuestro marcará la diferencia al cambiar esta página para nuestra comunidad y juntos aprovecharemos la oportunidad.
<br />
                            <br />

Creemos en tu capacidad para comenzar, nutrir y aumentar tu patrimonio neto.
<br />
                            <br />
No podríamos estar más emocionados de comenzar este viaje contigo. Más importante aún, no podemos esperar a verte prosperar y ver crecer tu dinero.
<br />
                            <br />

Creemos en ti, confiamos en ti y juntos llegaremos allí.
<br />
                            <br />

Gracias por confiar en nosotros. No te decepcionaremos. ¡El momento es Nuestro. Vamos por ello!
<br />
                            <br />

Diego / Fundador de Nuestro


                            <br />
                            <br />
                            <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>debito </b>
                    <b>deuda </b>
                    <b>comunidad </b>
                    <b>Credito </b>
                        </p>
                    </Col>
                </Row>
            </Container>
            {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
            <Container className="seeAllBlog">
                <Row style={{ color: '#ffffff' }}>
                    <Col>    <p>Entradas Recientes</p></Col>
                    <Col>  <a href="/es/blog" style={{ textAlign: 'Right', color: '#ffffff' }}><p> Ver todo </p></a></Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <img src={b1} />
                    </Col>
                    <Col>
                        <img src={b2} />
                    </Col>
                </Row>
                <Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
            </Container>
            <Footer />
        </div>
    )
}

export default Blog21Es;