import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog20Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/the-importance-of-building-credit"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
JMar 10, 2023
 &nbsp;&bull;&nbsp; 2 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>La Importancia de Generar Crédito</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Mi nombre es Israel Tovar (he/él), y estoy emocionado de haberme unido a Nuestro como su Director de Educación Financiera. Trabajaré arduamente para garantizar que los usuarios de Nuestro tengan éxito financiero.
                    <br/>
                    <br/>

Nací en Tijuana, México pero crecí entre las ciudades de Los Ángeles, California y Nashville, Tennessee. Recibí una licenciatura en Estudios Étnicos con honores y distinción de la Universidad de Yale y una Maestría en Educación de la Universidad de Stanford.
<br/>
Me desempeñé como maestro de estudios sociales de secundaria y preparatoria durante 5 años en San José, Nashville y Washington D.C. antes de convertirme en educador financiera.
<br/>
                    <br/>

Durante los últimos dos años, he tenido el inmenso privilegio de brindar educación financiera culturalmente sensible y antirracista a maestros de color en todo el país.
<br/>
Ahora con Nue$tro, estoy más que emocionado de ayudar a nuestras comunidades latinas a prosperar financieramente.

<br/>
                    <br/>

<b>¿Qué es Nue$tro?</b>
<br/>
<br/>
Nue$tro brinda a nuestras comunidades acceso a productos bancarios básicos y educación financiera para cerrar la brecha de educación financiera que afecta a la comunidad latina.
<br/>
<br/>
Nos esforzamos por ayudar a nuestras comunidades a profundizar su educación financiera, mejorar sus hábitos financieros y generar riqueza generacional.
<br/>
<br/>
Nue$tro fue creado para ayudar a nuestras comunidades a tomar el control de nuestro futuro financiero. Nue$tro es la forma de ayudar a nuestra gente en nuestros propios términos.
<br/>
<br/>
Una de las formas en que queremos que nuestras comunidades tomen el control de su futuro financiero es ayudándoles a construir y mejorar su crédito
<br/>
                    <br/>

<b>¿Por qué es importante generar crédito?</b>
<br/>
Cuando construye un buen puntaje de crédito, puede acceder más fácilmente a los recursos que necesita para vivir y ahorrar dinero en compras importantes de vida. Por ejemplo, cuando tiene un buen puntaje crediticio, se le pueden otorgar mejores tasas de interés cuando obtiene una hipoteca para una casa o un préstamo para un automóvil. Además, al tener un buen puntaje de crédito, puede ser aprobado más fácilmente para cosas importantes como el alquiler de un apartamento.  

<br/>
                    <br/>
                    Cinco rangos de puntuación de crédito:
<br/>
                    <br/>

<b>Excelente:</b> 800–850<br/>
<b>Muy bueno:</b> 740–799<br/>
<b>Bueno:</b> 670–739<br/>
<b>Justo:</b> 580–669<br/>
<b>Pobre:</b> 300–579

<br/>
                    <br/>
                    No es necesario tener un excelente puntaje de crédito. Solo debe evitar tener puntajes

regulares o bajos porque ese es el rango en el que los prestamistas (por ejemplo, propietarios, bancos) pueden rechazar o darle una tasa de interés más alta. Por supuesto, cuanto mayor sea el puntaje crediticio, mejor, porque un puntaje más alto indica a los prestamistas que es más probable que realice sus pagos mensuales a tiempo y en su totalidad. El puntaje promedio de los latinos en 2019 fue de 701, que cae en el rango de puntaje “Bueno” según (Investopedia).
<br/>
                    <br/>

                    Cinco factores que afectan su puntaje de crédito:
<ol>
<li><b>Historial de Pagos (35%):</b> Muestra si un prestatario ha pagado sus obligaciones a tiempo.</li>
<li><b>Monto total adeudado (30 %):</b> El porcentaje de crédito que utiliza un prestatario.</li>
<li><b>Duración del historial de crédito (15 %):</b> Cuánto tiempo ha estado utilizando el crédito un prestatario.</li>
<li><b>Tipos de crédito (10%):</b> La variedad de diferentes créditos (p. ej., préstamo de automóvil, tarjeta de crédito, hipoteca) que tiene un prestatario.</li>
<li><b>Crédito nuevo (10%): </b>La cantidad de cuentas de crédito nuevas que abre un prestatario. </li>
</ol>
Es importante resaltar que toma tiempo construir o mejorar su crédito. Sin embargo, cuanto antes tomes el control de tu crédito, antes verás los resultados.
<br/>
                    <br/>

                    Nue$tro está comprometido a ayudar a las comunidades a mejorar su crédito ya que nuestras comunidades merecen prosperar financieramente. ¡Te apoyaremos!
<br/>
                    <br/>

                    ¡Si se puede! 
<br/>
                    <br/>
                    <b>banco </b>
                    <b>finanzas</b>
                    <b>dinero </b>
                    <b>debito</b>
                    <b>deuda</b>
                    <b>Credito</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog20Es;