import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog8Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/take-control-of-your-debt"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jul 6, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>

                    <h1>Tome el control de su deuda</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Es importante aprender a tomar el control de su deuda no solo para que pueda acumular activos, sino también para que pueda tener más tranquilidad financiera.
                    <br/>
                    <br/>

El primer paso que debe tomar es anotar cuánto debe. Hacer esto puede parecer aterrador, abrumador y/o incómodo, pero es un paso necesario. Después de saber cuánto debe, puede desarrollar un plan a seguir.
<br/>
                    <br/>
En ese plan, asegúrese de utilizar una estrategia de pago de la deuda, celebre sus ganancias y recuerde activamente que tiene el control de su deuda. Aquí hay dos estrategias efectivas de pago de deuda que puede usar:

<br/>
                    <br/>
<b>Método bola de nieve:</b><br/><br/>

Este método prioriza el pago del préstamo más pequeño primero y luego se enfoca en el siguiente préstamo más pequeño. Repita hasta que esté libre de deudas. Este método es mejor para las personas que necesitan una gratificación instantánea. También ayuda a generar impulso en el proceso de pago del préstamo.
<br/>
                    <br/>
<b>Método de avalancha:</b>
<br/>
<br/>
Este método da prioridad al pago del préstamo con la tasa de interés más alta primero, y luego se enfoca en el préstamo con la segunda tasa de interés más alta. Repita hasta que esté libre de deudas. Este método funciona mejor para las personas que son más analíticas y no necesitan una gratificación instantánea.
<br/>
<br/>
<b>Puede ahorrar dinero en intereses:</b>
<br/>
<br/>
Debe elegir el método que funcione mejor para USTED porque hacerlo lo ayudará a hacer frente a su deuda lo mejor que pueda. ¡Así que esto es para tomar el control de nuestra deuda porque podemos y lo HAREMOS!
                    <br/><br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>debito </b>
                    <b>deuda </b>
                    <b>comunidad </b>
                    <b>Credito </b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog8Es;