import React from "react";
import "../../styles/style.css";
import "../../styles/slidein.css";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/navbar/spanish";
import Footer from "../../components/footer/spanish";
import left_banner from "../../assets/images/why_nuestro_es.png";
import video1 from "../../assets/videos/Nuestro_Info_Website.mp4";
import happy_boy from "../../assets/images/about_happy_boy.png";
import about1 from "../../assets/images/About1-8spanish.webp";
import about2 from "../../assets/images/About2-8spanish.webp";
import about3 from "../../assets/images/About3-8spanish.webp";
import about4 from "../../assets/images/aboutnuestro3-8spanish.png";
import behind_laugh from "../../assets/images/behind_laugh.jpg";

const AboutEs = () => {
  return (
    <div style={{ overflowX: "hidden", backgroundColor: "rgb(33, 67, 136)" }}>
      <Navbar langTogglehref="/en/about" />
      <Container style={{ paddingTop: 0 }}>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} className="left_about_box">
            <img alt="why nuestro" src={left_banner} className="left_about" />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className="right_about_box">
            <video
              loop="true"
              autoplay="true"
              muted
              className="right_about slide-in-right"
            >
              <source src={video1} type="video/mp4" />
            </video>
          </Col>
        </Row>
      </Container>
      <Container style={{ paddingTop: 0 }}>
        <Row>
        <Col xs={12} sm={12} md={6} lg={6} className="right_boy_box order-md-2 order-lg-2">
            <p className="right_boy">
              Nuestro fue creado para ayudar <br />a nuestra
              <span
                style={{
                  color: "rgb(232, 0, 120)",
                  fontFamily: "RockoUltraFLF",
                }}
              >
                {" "}
                Comunidad
              </span>{" "}
              a tomar el <br /> control de su futuro financiero.
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="left_boy_box order-md-1 order-lg-1">
            <img alt="happy user" src={happy_boy} className="left_boy" />
          </Col>
        </Row>
      </Container>
      <div className="white_about_container">
      <Container
        fluid
      >
        <Row style={{ paddingTop: "25px" }}>
          <Col sm={12} md={4} className="p-2">
            <img alt="save more" src={about1} width="200px" />
          </Col>
          <Col sm={12} md={4} className="p-2">
            <img alt="better financial habits" src={about2} width="200px" />
          </Col>
          <Col sm={12} md={4} className="p-2">
            <img alt="manage money smartly" src={about3} width="200px" />
          </Col>
        </Row>
        <Row>
          <p className="about_text">
            Creemos en el poder de la<span> identidad</span> y que nuestra
            <br />
            <span> liberación financiera</span> se puede lograr con algo que es{" "}
            <span>NUESTRO.</span>
          </p>
        </Row>
        <Row style={{ paddingBottom: "50px", textAlign: "center" }}>
          <center>
            <img alt="Nuestro unique" src={about4} className="unique" />
          </center>
        </Row>
        <Row style={{ paddingBottom: "50px", textAlign: "center" }}>
            <center>
              <h3 className="meet-our-team-heading">Conozca los rostros que aportan un conjunto único de habilidades, experiencias y perspectivas, uniéndolos bajo un compromiso compartido con la excelencia, conozca sus orígenes y descubra qué los inspira en sus funciones.</h3>
              <br/>
              <a href="/es/team" className='pt-2 pb-2 ps-4 pe-4 meet-our-team'>Conozca a nuestro equipo</a>
            </center>
          </Row>
      </Container>
      </div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className="left_behind_box">
            <h1>
              ¿Quién
              <br />
              está detrás
              <br /> de<span> nuestro?</span>
            </h1>
            <Col xs={12} sm={12} md={7} lg={7} className="left_help_box">
              <p>
                <span>Nuestro</span> es nuestra manera de ayudar a nuestra gente
                en nuestros términos. Sabemos que al cerrar la brecha de
                educación financiera está a nuestro alcance y ha de traer
                increíbles beneficios a nuestra<span> comunidad.</span>{" "}
              </p>
            </Col>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="right_behind_box">
            <img alt="Nuestro happy users " src={behind_laugh} />
            <Col xs={12} sm={12} md={7} lg={7} className="right_help_box">
              <p>
                Estamos comprometidos en crear herramientas para que usted sea
                exitoso y vea su dinero crecer. De esta manera contribuimos para
                ver sus sueños<span> hacerse realidad.</span>{" "}
              </p>
            </Col>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default AboutEs;
