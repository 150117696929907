import React from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterEn from '../../components/footer/english';
import NavbarEn from '../../components/navbar/english';
import "../../styles/teamcard.css";
import diego from "../../assets/images/team/diego.jpg";
import gustavo from "../../assets/images/team/gustavo.jpg";
import miguel from "../../assets/images/team/miguel.jpg";
import nicholas from "../../assets/images/team/nicholas.jpg";
import veronica from "../../assets/images/team/veronica.jpg";
import hector from "../../assets/images/team/hector.jpg";
import noelia from "../../assets/images/team/noelia.jpg";

const TeamEn = () => {
  return (
    <Container fluid style={{ overflow: 'hidden', backgroundColor: 'rgb(33, 67, 130)' }}>
      <NavbarEn langToggleHref="/es/team" />
      <Container style={{ textAlign: 'center' }}>
        <Row>
          <Col>
            <h1 style={{ fontFamily: 'RockoUltraFLF', color: 'rgb(232, 0, 120)', textAlign: 'left', margin: '20px 40px', fontSize: '70px' }}>
              MEET OUR TEAM
            </h1>
          </Col>
        </Row>
        <Row style={{ margin: '30px 0' }}>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={diego} />
                  </div>
                </div>
              </div>
              <div class="text-container">
                <h3>Diego Eguiarte</h3>
                <h6>Founder</h6>
                <div>
                  <p>As our founder, his vision is to support the community as they embark on their financial journey, providing them with greater opportunities for success.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={nicholas} />
                  </div>
                </div>
              </div>
              <div class="text-container">
                <h3>Nick Wijnberg</h3>
                <h6>Finance</h6>
                <div>
                  <p>Nick, with a background in financial consulting, is key in providing insights to financial analysis, strategy, and planning.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={veronica} />
                  </div>
                </div>
              </div>
              <div class="text-container">
                <h3>Veronica Uribe</h3>
                <h6>Credit</h6>
                <div>
                  <p>With extensive experience serving Latinos in U.S. banking, she champions diversity, cultural understanding, and breaking down language barriers in financial services.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: '30px 0' }}>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={noelia} />
                  </div>
                </div>
              </div>
              <div class="text-container-2">
                <h3>Noelia Fuentes</h3>
                <h6>Customer Success Manager</h6>
                <div>
                  <p>Noelia is an advocate for social justice, actively involved in pro-immigrant causes and engaged with Hispanic families through various organizations and prominent entities. No one understands our customers better than her.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={miguel} />
                  </div>
                </div>
              </div>
              <div class="text-container-2">
                <h3>Miguel Vega</h3>
                <h6>Growth</h6>
                <div>
                  <p>With his knack for developing culturally diverse markets across industries, he's the perfect person to get our products to every Latino looking to kick-start their financial journey.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div class="a-box">
              <div class="img-container">
                <div class="img-inner">
                  <div class="inner-skew">
                    <img src={hector} />
                  </div>
                </div>
              </div>
              <div class="text-container-2">
                <h3>Hector Alexis Davila</h3>
                <h6>Operations</h6>
                <div>
                  <p>With a dynamic blend of operational excellence and financial expertise, he is key to improving efficiency, productivity, and profitability.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterEn />
    </Container>
  )
}

export default TeamEn;