import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog19Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/a-secured-credit-card-can-help-our-comunidades-build-credit"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Mar 27, 2023
 &nbsp;&bull;&nbsp; 2 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                
                    <h1>Una tarjeta de crédito asegurada puede ayudar a nuestras comunidades a generar crédito</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    ¿Tienes entre 18 y 21 años? ¿estás listo para comenzar a construir tu crédito? Si es así, este articulo es para ti.

                    <br />
                            <br />
                            Obtener una tarjeta de crédito sin garantía antes de los 21 años no es fácil por una variedad de razones, hay tres formas en que puedes comenzar a generar crédito. 
<br/>
<br/>
Puedes hacerlo:
<br/>
<br/>
<ul>
<li>Obteniendo una tarjeta de crédito con la firma conjunta/abrir una cuenta con uno de sus padres o tutores.</li>
<li> Ser añadido en la tarjeta de crédito de un familiar. Tenga en cuenta que el historial crediticio y la actividad del usuario también afectan su puntaje.</li>
<li>Aprovechando una tarjeta de crédito asegurada.</li>
</ul>
<b>¿Qué es una tarjeta de crédito asegurada?</b> 
<br />
Una tarjeta de crédito asegurada, también conocida como tarjeta de creación de crédito, es una tarjeta de crédito respaldada por un depósito en efectivo. En otras palabras, el usuario, la persona que usa la tarjeta de crédito, debe realizar un depósito en efectivo al emisor de la tarjeta de crédito para abrir una cuenta.
<br />
                            <br />

La cantidad que deposite será su línea de crédito. Por ejemplo: si deposita $100, solo podrá usar $100 en esa tarjeta. Una tarjeta de crédito asegurada se usa como cualquier otra tarjeta de crédito para realizar pagos, pero solo puede usar la cantidad que depositó.
<br />
                            <br />

Una tarjeta de crédito asegurada tiene como objetivo ayudar a un usuario a establecer o mejorar su crédito. Si una persona no tiene ningún historial de crédito o necesita mejorar su crédito, puede ser más difícil para ellos calificar para una tarjeta de crédito sin garantía. Dado que las tarjetas de crédito aseguradas tienden a tener requisitos de aprobación más flexibles, obtener una tarjeta de crédito asegurada puede ser un trampolín para calificar para otras tarjetas de crédito. Si un usuario de tarjeta de crédito asegurada demuestra que puede usar el crédito de manera responsable y ha construido o mejorado su puntaje de crédito, podrás hacer la transición a una tarjeta de crédito sin garantía.
<br />
                            <br />

Por último, si cierra su cuenta de tarjeta de crédito asegurada en buen estado que significa que ha realizado todos sus pagos a tiempo y en su totalidad, recuperará su depósito.


<br />
                            <br />


<b>3 consejos para optimizar una tarjeta de crédito asegurada</b>
<br />
Aquí hay tres consejos que recomendamos al usar una tarjeta de crédito asegurada para establecer un crédito sólido:
<ul>
<li> Pague su saldo a tiempo y en su totalidad </li>
<li>No gastes más en tu tarjeta de crédito de lo que tienes en tu cuenta bancaria</li>
<li>Cuando tenga un puntaje de crédito establecido, verifíquelo regularmente</li>
</ul>

<b>¿Por qué una Tarjeta de Crédito Garantizada Nuestro?</b>
<br />
Nue$tro diseñó una tarjeta de crédito asegurada teniendo en cuenta las necesidades de nuestras comunidades. Los principales inconvenientes de las tarjetas de crédito garantizadas son que pueden tener tarifas muy altas y/o un APR alto.  La tarjeta de crédito garantizada de Nue$tro, tiene cero comisiones y una APR del 0%.

<br />
                            <br />
                            Entendemos profundamente a nuestras comunidades y estamos comprometidos a brindarles los productos de préstamo más accesibles que también sean culturalmente adaptables.

<br />
                            <br />
                            Esta es nuestra manera de ayudar a nuestra gente en nuestros propios términos.
<br />
                            <br />
                            Si estás listo para aprovechar nuestra tarjeta de crédito asegurada para ayudarte a generar crédito, ¡Regístrate hoy en nuestra lista de espera!
<br />
                            <br />

                            ¡Si tienesDACA, puedes inscribirte en nuestra lista de espera para una prueba beta del producto!
<br />
                            <br />

                            No podemos esperar a verte prosperar financieramente porque...

<br/>
                    <br/>
                    ¡Si se puede!
                    <br/>
                    <br/>
________________________________
<br/>
                    <br/>
                    <ol>
<li>Un tipo de tarjeta de crédito que no requiere que deposites dinero para usarla.</li>
<li>La institución financiera prestamista (por ejemplo, un banco)</li>
<li>La línea de crédito (o también conocida como límite de crédito) es la cantidad de dinero que puede usar     en su tarjeta de crédito</li>
<li>Un tipo de tarjeta de crédito que no requiere que deposites dinero para usarla. El límite de crédito lo determina el emisor de la tarjeta.</li>
<li>La tasa de porcentaje anual (APR) es la tasa de interés anual que debe pagar sobre el monto que debe</li>
                    </ol>

                    __________________________
                    <br/>5 La tasa de porcentaje anual (APR) es la tasa de interés anual que debe pagar sobre el monto que debe

<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>deuda </b>
                    <b>Credito </b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog19Es;