import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";




const Blog5En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };


    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/es/blog/where-can-i-start-investing"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
Jul 24, 2023
 &nbsp;&bull;&nbsp; 1 min read</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                    <h1>Where can I start investing?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
We know that getting started on investing can feel overwhelming, but in this post, you’ll learn how to get started.
<br/>
                    <br/>
You can begin investing through retirement accounts (the most popular being a 401k or 403b), Individual Retirement Accounts, and Brokerage accounts. 
<br/>
                    <br/>
A 401k is an employer-based account that allows you to have a percentage of your paycheck go directly into an investment account which your employer can also agree to match or pay part of the total contribution. 
<br/>
                    <br/>
Individual Retirement accounts (or also known as IRAs), on the other hand, vary depending on when you decide to pay taxes on the money added. 
<br/>
                    <br/>
For traditional IRAs, you don’t pay for taxes at the moment, until your money is withdrawn in retirement. 
<br/>
                    <br/>
While with Roth IRAs you do pay for taxes on the money you add and none when withdrawn. The maximum contribution for both of these accounts in any given calendar year is $6,500. 
<br/>
                    <br/>
We recommend you assess these accounts and proceed with the one that best fits your financial situation.  
<br/>
                    <br/>
Recuerda, the earlier you start investing in your future, the more interest your money will gain.
<br/>
                    <br/>
¡Sí se puede, mi gente!
<br/>
                    <br/>
                    <b>Savings </b>
                    <b>Credit </b>
                    <b>Ahorros </b>
                    <b>Credito </b>
                    <b>Building </b>
                    <b>credit </b>
                    <b>Fintech </b>
                    <b>Comunidad </b>
                    <b>Latino </b>
                    <b>debt </b>
                    <b>debit</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p> Recent Posts </p></Col>
                <Col>  <a href="/en/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> See All </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
    </Col>
    <Col>
    <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog5En;