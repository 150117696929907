import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/english";
import Footer from "../../footer/english";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/Diego_Eguiarte.jpg";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog21En = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{ backgroundColor: 'rgb(33, 67, 136)' }}>
            <Navbar langToggleHref="/es/blog/bienvenidos-a-nuestro-the-moment-is-ours"/>
            <Container className="resp_blog">
                <Row>
                    <Col>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                            <p style={{ fontSize: '13px' }}><img src={dp} width="40px" style={{borderRadius:'20px'}}/> &nbsp;Diego Eguiarte
 &nbsp;&bull;&nbsp;
                                Oct 21, 2022
                                &nbsp;&bull;&nbsp; 1 min read</p>
                        </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
                        
                        <h1>Bienvenidos a Nuestro. The Moment is Ours.</h1>
                        <br />
                        <p>
                        Updated: Jun 30, 2023
                        <br />
                            <br />
I started Nuestro to close the financial literacy gap, keeping our communities from taking full advantage of the existing financial products and services offered by mainstream organizations.
<br />
                            <br />

Nuestro is focused on developing better financial habits with our users. Providing an entry-level products portfolio that will prepare our user community for interacting successfully with more complex products further down in their financial journey.

<br />
                            <br />
We know that better financial habits, combined with excellent work ethics and talent, is a clear path for creating generational wealth for our families. 
<br />
                            <br />

Our communities have proven great capabilities for starting and growing businesses, for generating jobs and increasing the flow of cash throughout. 
<br />
                            <br />

But that is not enough, we still have to learn how to better manage our cash, invest, and leverage the multiple available financial products that turn our money into wealth. 
<br />
                            <br />

Nuestro will make a difference in turning this page for our community, and together we will seize the opportunity.
<br />
                            <br />

We believe in your capacity for starting, nurturing, and growing your net worth. 
<br />
                            <br />

We couldn’t be more excited to start this journey with you. More importantly, we can’t wait to see you thrive and watch your money grow.

<br />
                            <br />
We believe in you, we trust you, and together we will get there. 

<br />
                            <br />
Thank you for trusting us. You will not be disappointed. El momento es Nuestro. LFG! 

<br />
                            <br />
Diego / Nuestro Founder
                            <br />
                            <br />
                            <b>Savings </b>
                            <b>Credit </b>
                            <b>Ahorros </b>
                            <b>Credito </b>
                            <b>Building </b>
                            <b>credit </b>
                            <b>Fintech </b>
                            <b>Comunidad </b>
                            <b>Latino </b>
                            <b>debt </b>
                            <b>debit</b>
                        </p>
                    </Col>
                </Row>
            </Container>
            {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
            <Container className="seeAllBlog">
                <Row style={{ color: '#ffffff' }}>
                    <Col>    <p> Recent Posts </p></Col>
                    <Col>  <a href="/en/blog" style={{ textAlign: 'Right', color: '#ffffff' }}><p> See All </p></a></Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <img src={b1} />
                    </Col>
                    <Col>
                        <img src={b2} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Nuestro Features, Part 3: Enrico Lopez-Yañez, Principal Pops Director at the Nashville...</h1>
                    </Col>
                    <Col>
                        <h1>Nuestro Features, Part 2: Elizabeth Narváez-Vega, College Success Coach at Conexión...

                        </h1>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default Blog21En;