import React, { useState } from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from "../../navbar/spanish";
import Footer from "../../footer/spanish";
import "../../../styles/blog_style.css"
import b1 from "../../../assets/images/rb1.png";
import dp from "../../../assets/images/p.png";
import b2 from "../../../assets/images/rb2.webp";
import shareIcon from "../../../assets/icons/share.png"; // Import the share icon image
import RSSUsage from "../../share_social/english";



const Blog13Es = () => {

    const [isShareOpen, setIsShareOpen] = useState(false);

    const handleShareIconClick = () => {
      setIsShareOpen(true);
    };
  
    const handleCloseShare = () => {
      setIsShareOpen(false);
    };

    return (
        <div style={{backgroundColor:'rgb(33, 67, 136)'}}>
        <Navbar langToggleHref="/blog/help-what-are-banking-transactions"/>
        <Container className="resp_blog">
            <Row>
                <Col>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <p style={{fontSize:'13px'}}><img src={dp} width="40px"/> &nbsp;Panthelys Theodorakopoulos &nbsp;&bull;&nbsp; 
May 24, 2023
 &nbsp;&bull;&nbsp; 1 Min. de lectura</p>
                </div>
              <div style={{ cursor: 'pointer' }} onClick={handleShareIconClick}>
                <img src={shareIcon} alt="Share" style={{ width: '20px' }} />
              </div>
            </div>
               
                    <h1>¡Ayuda! ¿Qué son las Transacciones Bancarias?</h1>
                    <br/>
                    <p>
                    ¡Hola!
                    <br/>
                    <br/>
                    Si no tiene idea de la terminología bancaria básica que cree debe conocer, está en el lugar correcto 🙂 Este artículo explicará cuatro términos bancarios: transferencias ACH, transferencias bancarias, cajeros automáticos y transacciones punto a punto (P2P)
                    <br/>
                    <br/>

<b>Transferencias ACH</b><br/><br/>

Estas son transferencias electrónicas que ocurren entre bancos y cooperativas de crédito. Las transferencias deben verificarse, son solo para uso doméstico y por lo general, son gratuitas o de bajo costo. Ejemplos de estas transacciones son: el depósito directo y el pago de sus facturas en línea.
<br/>
                    <br/>
                    <b>Transferencias bancarias</b><br/><br/>

                    Consisten en mover dinero de un banco a otro. Estas transferencias no necesitan ser verificadas, son mucho más rápidas que las transferencias ACH y pueden enviarse internacionalmente. Estas transferencias tienen una tarifa. 
<br/>
                    <br/>
                    <b>Cajeros automáticos</b><br/><br/>

                    Estas son computadoras especializadas que le permiten completar transacciones bancarias sin hablar con un representante del banco. Son convenientes porque le permiten realizar transacciones rápidas de autoservicio, como depósitos y retiros de efectivo.
<br/>
                    <br/>

                    <b>Transacciones punto a punto (P2P)</b><br/><br/>

                    Estas transacciones son dinero que envías directamente a otra persona a través de sistemas de pago como Venmo y Cash App. Son un tipo de transferencia ACH, mantienen la privacidad de los datos de su cuenta bancaria y todo lo que necesita para realizarlos con la dirección de correo electrónico o el número de teléfono del destinatario.

<br/>
                    <br/>
                    Esperamos que ahora tenga una mejor comprensión de estos términos y se sienta más seguro con la banca. Porque merece mantener su dinero seguro y usarlo convenientemente.
<br/>
                    <br/>
                    <b>banco </b>
                    <b>ahorra </b>
                    <b>finanzas </b>
                    <b>dinero </b>
                    <b>debito</b>
                    <b>deuda</b>
                    <b>credito</b>
                    <b>comunidad</b>
</p>
                </Col>
            </Row>
        </Container>
        {isShareOpen && <RSSUsage onClose={handleCloseShare} buttonLink="" />}
        <Container className="seeAllBlog">
            <Row style={{color:'#ffffff'}}>
                <Col>    <p>Entradas Recientes</p></Col>
                <Col>  <a href="/es/blog" style={{textAlign:'Right', color:'#ffffff'}}><p> Ver todo </p></a></Col>
            </Row>
        <Row>
    <Col style={{textAlign:'center'}}>
    <img src={b1}/>
    </Col>
    <Col>
    <img src={b2}/>
    </Col>
</Row>
<Row>
    <Col>
    <h1>Nuestro Reseña, Parte 3: Enrico Lopez-Yañez, Director Principal de Pops en la Sinfónica de...</h1>
    </Col>
    <Col>
    <h1>Nuestro Reseña: Elizabeth Narváez-Vega, Asesora de Éxito Universitario en Conexión...

</h1>
    </Col>
</Row>
        </Container>
        <Footer/>
        </div>
    )
}

export default Blog13Es;